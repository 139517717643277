import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import ImageBackground from 'UI/atoms/ImageBackground';
import SVGBackground from 'UI/atoms/SVGBackground';

const FooterContainer = styled.footer<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor ?? 'inherit'};
  margin-top: -1px;
`;

interface FooterProps {
  children: ReactNode;
  backgroundColor?: string;
}

function Footer({ children, backgroundColor }: FooterProps) {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });

  return (
    <FooterContainer backgroundColor={backgroundColor}>
      <SVGBackground src={'/assets/FooterRightRockBack.svg'} width={isMobile ? '40%' : '30%'} right="4%" bottom="5vw" />
      <SVGBackground
        src={'/assets/FooterOW.svg'}
        width={isMobile ? '20%' : '15%'}
        right={isMobile ? '15%' : '12%'}
        bottom="5vw"
      />
      <SVGBackground src={'/assets/FooterRightRockFront.svg'} width={isMobile ? '20%' : '15%'} right="0" bottom="5vw" />
      <SVGBackground src={'/assets/Ground.svg'} width="102%" bottom="-5px" />
      <SVGBackground src={'/assets/Bush.svg'} width="18%" right="0" bottom="-5px" />
      <ImageBackground src={'/assets/Copyright.png'} bottom="0.8vw" alt="copyright" width="160px" />
      <SVGBackground src={'/assets/FooterLeftDeco.svg'} width="20%" left="0" bottom="-5px" />
      {children}
    </FooterContainer>
  );
}

export default Footer;
