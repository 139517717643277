import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';

interface SVGBackgroundProps {
  src: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
}

const BackgroundContainer = styled.div<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
}>`
  position: absolute;
  pointer-events: none;
  top: ${(props) => props.top ?? ''};
  bottom: ${(props) => props.bottom ?? ''};
  left: ${(props) => props.left ?? ''};
  right: ${(props) => props.right ?? ''};
  width: ${(props) => props.width ?? 'auto'};
  height: auto;
  max-width: 100%;

  svg {
    width: 100%;
    height: auto;
  }
`;

function SVGBackground({ src, top, bottom, left, right, width }: SVGBackgroundProps) {
  return (
    <BackgroundContainer top={top} bottom={bottom} left={left} right={right} width={width}>
      {src.includes('svg') ? <ReactSVG src={src} /> : <img src={src} width="100%" />}
    </BackgroundContainer>
  );
}

export default SVGBackground;
