import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { useRecoilState } from 'recoil';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';
import { theme } from 'theme/theme';
import HighlightedText from 'UI/atoms/HighlightedText';
import { Stacker } from 'UI/atoms/Spacer';
import SVGButton from 'UI/atoms/SVGButton';
import Footer from 'UI/molecules/Footer';

const IconContainer = styled(CenteredFlexBox)`
  max-width: 1000px;
  width: 100%;
  justify-content: space-evenly;
  margin: 50px 0;
  margin-bottom: 280px;
`;

const FooterTitle = styled(HighlightedText)`
  text-shadow: 1px 1px 2px ${(props) => props.theme.colors.darkGray};
  line-height: 60px;
`;

interface BBOWFooterProps {
  backgroundColor?: string;
}

const iconNames = ['YT', 'IG', 'FB', 'PF'];
const links = [
  'https://www.youtube.com/c/BabySharkKO',
  'https://www.instagram.com/babyshark.kr',
  'https://www.facebook.com/Babyshark.korea/',
  'https://www.pinkfong.com/ko/?utm_source=BBOW&utm_medium=Footer',
];

const linksEn = [
  'https://www.youtube.com/c/BabySharkOfficial',
  'https://www.instagram.com/babysharkbrooklyn.official/',
  'https://www.facebook.com/babyshark.global',
  'https://www.pinkfong.com/en/',
];

function BBOWFooter({ backgroundColor = theme.colors.homeContent }: BBOWFooterProps) {
  const [lang] = useRecoilState(langState);
  const currentLink = lang.code === 'ko' ? links : linksEn;
  const footerDesc = lang.code === 'ko' ? `귀여움으로 우주 정복!\n 아기상어 올리와 친구해요!` : "Let's be friends!";

  return (
    <Footer backgroundColor={backgroundColor}>
      <Stacker margin="medium" />
      <FooterTitle size="50px" color="white">
        {footerDesc}
      </FooterTitle>
      <IconContainer>
        {iconNames.map((iconName, index) => {
          return (
            <SVGButton
              key={`${iconName}-${index}`}
              src={`/assets/icons/${iconName}.svg`}
              onClick={() => window.open(currentLink[index])}
            />
          );
        })}
        <a href="https://www.thepinkfongcompany.com/?utm_source=babysharkweb&utm_medium=footer_icon">
          <img src="/assets/icons/TPC.ico" />
        </a>
      </IconContainer>
    </Footer>
  );
}

export default BBOWFooter;
