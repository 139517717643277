import styled from '@emotion/styled';
import { ReactNode } from 'react';

const NoStyleButton = styled.button`
  background: none;
  border: none;
  position: relative;
`;

interface OnlyButtonProps {
  children: ReactNode;
  onClick?: () => void;
}

function OnlyButton({ children, onClick }: OnlyButtonProps) {
  return <NoStyleButton onClick={onClick}>{children}</NoStyleButton>;
}

export default OnlyButton;
