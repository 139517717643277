import styled from '@emotion/styled';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { MobileContainer } from 'styled/MobileContainer';
import { BBOWFooter, BBOWHeader, Tab, TabsWithWave, Spacer } from 'UI';
import Desc from './tabs/Desc';
import Watch from './tabs/Watch';
import Event from './tabs/Event';
import { theme } from 'theme/theme';
import { SVGImg } from 'styled/SVGImg';
import { langState } from 'atoms/lang';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const SandColor = '#fffcc1';

const CarouselContainer = styled.section`
  padding-top: 5vh;
  background-image: url('/assets/backgrounds/HomeBG.png');
  background-size: cover;
`;

const ContentSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.colors.homeContent};
`;

const Popup = styled.div<{ hidden: boolean }>`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  img {
    border-radius: 30px;
  }
`;

function Home({ tab }: { tab: number }) {
  const [currentTab] = useState(tab);
  const [lang] = useRecoilState(langState);
  const [popupHidden, setPopupHidden] = useState(tab !== 0);
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });

  return (
    <MobileContainer>
      <BBOWHeader />
      <Spacer mb="-2px" />

      <CarouselContainer>
        <Carousel showStatus={false} showThumbs={false}>
          {lang.code === 'ko' ? (
            <Link to="/event/content">
              <img src={'/assets/banners/WatchingEventBanner.png'} style={{ width: isMobile ? '100%' : '50vw' }} />
            </Link>
          ) : (
            <Link to="/event">
              <img src={'/assets/banners/CanDooIt.png'} style={{ width: isMobile ? '100%' : '70vw' }} />
            </Link>
          )}
          {lang.code === 'ko' ? (
            <Link to="/event/2">
              <img src={'/assets/banners/EventEmbedBanner.png'} style={{ width: isMobile ? '100%' : '70vw' }} />
            </Link>
          ) : (
            <SVGImg
              width="600px"
              src={`/assets/banners/HomeBanner_${lang.code}.svg`}
              alt="Baby Shark Olly and William Banner"
            />
          )}
          <Link to="/activity">
            <SVGImg
              width="600px"
              src={`/assets/banners/HomeBanner2_${lang.code}.svg`}
              alt="Baby Shark Olly and William Banner"
            />
          </Link>
        </Carousel>
        <TabsWithWave value={currentTab}>
          <Tab key="0" text={t('home.tabs.desc')} stupid onClick={() => history.push('/')} />
          <Tab
            key="1"
            text={t('home.tabs.watch')}
            stupid
            onClick={() => {
              history.push('/desc');
            }}
          />
          <Tab key="2" text={t('home.tabs.event')} stupid onClick={() => history.push('/event')} />
        </TabsWithWave>
      </CarouselContainer>

      <ContentSection>{currentTab === 0 ? <Desc /> : currentTab === 1 ? <Watch /> : <Event />}</ContentSection>

      <BBOWFooter backgroundColor={currentTab === 2 ? SandColor : theme.colors.homeContent} />

      <Popup hidden={popupHidden || lang.code === 'en'}>
        <img src="/assets/banners/PopupBanner.png" useMap="#popupMap" />
      </Popup>
      <map name="popupMap">
        <area shape="rect" coords="579,23,620,66" onClick={() => setPopupHidden(true)} id="btn-close" />
        <area
          shape="rect"
          coords="122,502,516,595"
          onClick={() => {
            setPopupHidden(true);
            history.push('/event/2');
          }}
          id="btn-gotest"
        />
        <area shape="rect" coords="252,606,396,635" onClick={() => setPopupHidden(true)} id="btn-later" />
      </map>
    </MobileContainer>
  );
}

export default Home;
