import { css } from '@emotion/react';

const fontPath = '/assets/fonts';

export const globalStyle = css`
  * {
    @font-face {
      font-family: 'PinkfongBT';
      font-weight: 700;
      src: url('${fontPath}/PinkfongBabyTuxedo.ttf') format('truetype');
    }

    @font-face {
      font-family: 'NotoSans';
      font-weight: 600;
      src: url('${fontPath}/NotoSans-Thin.woff2') format('woff2');
    }

    @font-face {
      font-family: 'NotoSans';
      font-weight: 700;
      src: url('${fontPath}/NotoSans-Medium.woff2') format('woff2');
    }

    @font-face {
      font-family: 'NotoSans';
      font-weight: 800;
      src: url('${fontPath}/NotoSans-Bold.woff2') format('woff2');
    }

    @font-face {
      font-family: 'ManwhaGage';
      font-weight: 600;
      src: url('${fontPath}/ManwhagageL.ttf') format('truetype');
    }

    @font-face {
      font-family: 'ManwhaGage';
      font-weight: 700;
      src: url('${fontPath}/ManwhagageR.ttf') format('truetype');
    }

    @font-face {
      font-family: 'ManwhaGage';
      font-weight: 800;
      src: url('${fontPath}/ManwhagageB.ttf') format('truetype');
    }

    font-size: 12px;
    font-weight: 700;
    font-family: 'NotoSans';
  }
`;
