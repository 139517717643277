import styled from '@emotion/styled';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { theme } from 'theme/theme';
import { Tab, Tabs, Header, DropDownMenu, LoginButton } from 'UI';
import { useMediaQuery } from 'react-responsive';
import { SVGImg } from 'styled/SVGImg';
import { useRecoilState } from 'recoil';
import { langState } from 'atoms/lang';
import { useTranslation } from 'react-i18next';

const Menu = styled.div`
  font-size: 22px;
  color: #c8c8c8;
  padding: 12px 18px;
  border-radius: 5px;
  :hover {
    background: ${(props) => props.theme.colors.gray};
  }
`;

// 밑의 라인을 any가 아닌 다른 적합한 타입을 쓰도록 수정해야함
const tabTitles: any = {
  '/': 0,
  '/desc': 0,
  '/event': 0,
  '/activity': 1,
  '/shop': 2,
};

const HeaderTabs = styled(Tabs)`
  width: 100%;
`;

function BBOWHeader() {
  const history = useHistory();
  const [currentIndex] = useState(tabTitles[history.location.pathname]);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });
  const [lang, setLang] = useRecoilState(langState);
  const { t } = useTranslation();

  function changeLang(code: string) {
    setLang({ code: code });
    setDropDownVisible(false);
  }

  function onChangeTab(routeTo: '/' | '/shop' | '/activity') {
    history.push(routeTo);
  }

  return (
    <Header>
      <div onClick={() => history.push('/')}>
        {isMobile ? (
          <SVGImg width="60px" src="/assets/icons/BSSymbol.svg" />
        ) : (
          <SVGImg src={`/assets/icons/BSLogoDesktop_${lang.code}.svg`} width="120px" />
        )}
      </div>
      <HeaderTabs value={currentIndex}>
        <Tab key="0" text="Home" icon="Home" color={theme.colors.home} onClick={() => onChangeTab('/')} />
        <Tab
          key="1"
          text="Activity"
          icon="Activity"
          color={theme.colors.activity}
          onClick={() => onChangeTab('/activity')}
        />
        <Tab key="2" text="Shop" icon="Shop" color={theme.colors.shop} onClick={() => onChangeTab('/shop')} />
        <Tab
          key="3"
          text="Plus"
          icon="Plus"
          color={theme.colors.pinkfongPlus}
          onClick={() => window.open(t('UI.pinkfong_plus'))}
        />
      </HeaderTabs>
      <div onMouseEnter={() => setDropDownVisible(true)} onMouseLeave={() => setDropDownVisible(false)}>
        <SVGImg src="/assets/icons/GlobalIcon.svg" width="40px" />
        <DropDownMenu visible={dropDownVisible}>
          <Menu onClick={() => changeLang('en')}>English</Menu>
          <Menu onClick={() => changeLang('ko')}>한국어</Menu>
        </DropDownMenu>
      </div>
      <LoginButton />
    </Header>
  );
}

export default BBOWHeader;
