import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { Stacker, TextWithBackground, Text, HighlightedText, Card, Spacer, GradationButton, ImageBackground } from 'UI';
import GiveawayCard, { BorderlessGiveawayCard, GiveawayCardEvent1 } from 'UI/organisms/GiveawayCard';
import { SVGImg } from 'styled/SVGImg';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';

interface EventContentsProps {
  currentEvent: number;
}

const EventContainer = styled.div`
  display: flex;
  position: relative;
  padding: 45px 0px;
  flex-direction: column;
  background-color: #f9f59f;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ResponsiveCardContainer = styled.div`
  display: flex;
  position: relative;
  padding: 45px 0px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  background-color: #f9f59f;
  width: 50vw;
  justify-content: space-evenly;
  align-items: center;
`;

const BackgroundDiv = styled(ColumnFlexBox)`
  margin-top: -2px;
  background: #fffcc1;
  width: 100%;
  position: relative;
`;

const BackgroundDivEvent2 = styled(ColumnFlexBox)`
  margin-top: -2px;
  width: 100%;
  position: relative;
  background-color: #f9f59f;
`;

const CardContainer = styled.div`
  display: flex;
  position: relative;
  padding: 45px 0px;
`;

const GiveawayTable = styled.table`
  position: relative;
  color: #509b32;
  border-collapse: collapse;
  & > thead {
    & > th {
      font-size: 22px;
      padding: 2px 48px;
      position: relative;
      border-bottom: 2px solid #8cd75a;
    }
    & > th:not(:last-child) {
      border-right: 2px solid #8cd75a;
    }
  }
  & > tbody {
    & > tr > td {
      text-align: center;
      font-size: 22px;
      padding: 6px 24px;
      color: #be7d19;
    }
    & > tr > td:not(:last-child) {
      border-right: 2px solid #8cd75a;
    }
    & > tr > td:first-child {
      color: #509b32;
    }
  }
`;

const DooItTable = styled.table`
  position: relative;
  color: white;
  border-collapse: collapse;
  text-align: left;
  & > tbody {
    vertical-align: top;
    & > tr:not(:last-child) {
      border-bottom: 1px solid white;
    }
    & > tr > td {
      font-size: 22px;
      color: white;
      padding: 10px 22px;
    }
    & > tr > td:first-child {
      width: 30%;
    }
  }
`;

const PrizeCard = styled(Card)`
  border-radius: 45px;
  width: 250px;
  height: 250px;
  text-align: center;
  border: 0;
`;

const PrizeCardContainer = styled(CenteredFlexBox)`
  & > div:first-child {
    margin-right: 20px;
  }
`;

const PrizeWrapper = styled(Card)`
  padding: 24px 0px;
`;

function EventContent1() {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });

  return (
    <>
      <Stacker margin="large" />
      <EventContainer>
        <TextWithBackground color="white" backgroundColor="#F66AA8" size="48px" borderRadius="0" padding="12px 24px">
          EVENT
        </TextWithBackground>
        <Stacker margin="xl" />
        <div style={{ textAlign: 'center' }}>
          <HighlightedText color="#A556EA" borderColor="white" size="44px">
            {`이벤트 기간: ${isMobile ? '\n' : ''}`}
          </HighlightedText>
          <Stacker margin="xs" />
          <HighlightedText color="#FF4398" borderColor="white" size="44px">
            2022년 5월 16일 ~ 6월 15일
          </HighlightedText>
          <Stacker margin="xs" />
        </div>
        <ResponsiveCardContainer>
          <img src="/assets/event/MockUp.png" style={{ marginRight: '15px' }} />
          <Spacer mt="50px" />
          <ColumnFlexBox>
            <Card
              width={isMobile ? 'fitScreen' : 'large'}
              height="small"
              borderColor="white"
              backgroundColor="white"
              shadow
              padding="6px 10px">
              <HighlightedText color="#FF4398" size="26px">
                참여방법
              </HighlightedText>
              <Stacker margin="xs" />
              <Text size="20px" color="black" marks={['결과 이미지 캡쳐']} markColor="#EBE69D" markStyle="bold">
                1. 해양생물 테스트 결과 이미지 캡쳐하기
              </Text>
              <Text
                size="20px"
                color="black"
                marks={['@babyshark.kr 인스타 팔로우']}
                markColor="#EBE69D"
                markStyle="bold">
                {`2. @babyshark.kr 인스타 팔로우하고,\n`}
              </Text>
              <Text
                size="20px"
                color="black"
                marks={['스토리에 테스트 결과 공유']}
                markColor="#EBE69D"
                markStyle="bold">
                {`3. 인스타그램 스토리에 테스트 결과 공유하기!\n`}
              </Text>
              <Text size="20px" color="black" marks={['꼭! @babyshark.kr 태그']} markColor="#EBE69D" markStyle="bold">
                4. 꼭! @babyshark.kr 태그 잊지 말아주세요!
              </Text>
            </Card>
            <Stacker margin="small" />
            <Card
              width={isMobile ? 'fitScreen' : 'large'}
              height="auto"
              borderColor="white"
              backgroundColor="white"
              shadow
              padding="4px 10px">
              <HighlightedText color="#FF4398" size="26px">
                당첨꿀팁
              </HighlightedText>
              <Stacker margin="xs" />
              <Text size="20px" color="black" marks={['공유 & 친구 태그하면']} markColor="#EBE69D" markStyle="bold">
                {`인스타그램 스토리에 공유 & 친구 태그하면\n`}
              </Text>
              <Text markStyle="bold" size="20px" color="black" markColor="#EBE69D">
                {`경품 당첨 확률이 2배 UP! 태그한 친구가 많을수록 UP&UP!\n`}
              </Text>
            </Card>
            <Stacker margin="small" />
            <Card
              width={isMobile ? 'fitScreen' : 'large'}
              height="small"
              borderColor="white"
              backgroundColor="white"
              shadow
              padding="4px 10px">
              <HighlightedText color="#FF4398" size="26px">
                필수 해쉬태그
              </HighlightedText>
              <Stacker margin="xs" />
              <Text size="20px" color="black" markColor="#EBE69D">
                {`게시물 작성 또는 스토리 공유 시\n 해쉬태그를 작성해주세요!\n`}
              </Text>
              <Stacker margin="small" />
              <HighlightedText color="black" size="20px">
                #해양생물테스트 #아기상어올리와윌리엄
              </HighlightedText>
            </Card>
          </ColumnFlexBox>
        </ResponsiveCardContainer>
      </EventContainer>

      <BackgroundDiv>
        <Stacker margin="xl" />

        <TextWithBackground color="white" backgroundColor="#F66AA8" size="48px" borderRadius="0" padding="12px 24px">
          당첨상품
        </TextWithBackground>
        <Stacker margin="medium" />

        <ResponsiveCardContainer style={{ backgroundColor: 'transparent' }}>
          <GiveawayCardEvent1
            imgSrc="/assets/event/NaverPay.png"
            text={`네이버페이${isMobile ? '\n' : ''} 3만원권\n(3명)`}
          />
          <Stacker margin="medium" />
          <GiveawayCardEvent1
            imgSrc="/assets/event/DollSet.png"
            text={`아기상어${isMobile ? '\n' : ''} 올리와 윌리엄\n 인형세트 (5명)`}
          />
          <Stacker margin="medium" />
          <GiveawayCardEvent1
            imgSrc="/assets/event/Sticker.png"
            text={`아기상어${isMobile ? '\n' : ''} 올리와 윌리엄\n 가방스티커 ${isMobile ? '\n' : ''}(10명)`}
          />
        </ResponsiveCardContainer>
        <Stacker margin="xl" />

        <TextWithBackground size="24px" backgroundColor="#fff6a8" color="black" font="NotoSans">
          {`이벤트 경품은 사정에 따라 ${isMobile ? '\n' : '변경될 수 있습니다.'} `}
        </TextWithBackground>
        <Stacker margin="xs" />
        {isMobile && (
          <TextWithBackground size="24px" backgroundColor="#fff6a8" color="black" font="NotoSans">
            변경될 수 있습니다.
          </TextWithBackground>
        )}
        <Stacker margin="xl" />
      </BackgroundDiv>
    </>
  );
}

function EventContent2() {
  const { t } = useTranslation();

  return (
    <>
      <TextWithBackground color="white" backgroundColor="#ff9b37" size="41px" borderRadius="30px" padding="12px 24px">
        {t('home.event.how')}
      </TextWithBackground>
      <Stacker margin="xl" />

      <BackgroundDivEvent2>
        <CenteredFlexBox>
          <SVGImg src="/assets/event/WatchingExample.svg" width="450px" />
          <Spacer mr="5vw" />
          <ColumnFlexBox>
            <div style={{ marginLeft: '-230px' }}>
              <Text marks={['필수 해시태그']} markColor="#F8E38F" color="#C67600" size="22px">
                필수 해시태그와 함께
              </Text>
              <Spacer mb="5px" />
              <Text marks={['본방사수 인증사진']} markColor="#F8E38F" color="#C67600" size="22px">
                본방사수 인증사진을 업로드해주세요!
              </Text>
            </div>
            <Stacker margin="xl" />
            <div>
              <TextWithBackground
                color="white"
                backgroundColor="#73DB41"
                size="24px"
                borderRadius="30px"
                padding="6px 12px">
                필수 해시태그
              </TextWithBackground>
              <Stacker margin="xs" />
              <HighlightedText color="#2A9C10" size="26px" lineHeight="32px">
                {`#아기상어올리와윌리엄\n#아기상어본방사수`}
              </HighlightedText>
              <Stacker margin="large" />
              <TextWithBackground
                color="white"
                backgroundColor="#73DB41"
                size="24px"
                borderRadius="30px"
                padding="6px 12px">
                당첨 꿀팁
              </TextWithBackground>
              <Stacker margin="xs" />
              <Text color="#C97D0E" size="22px">
                {`1. @babyshark.kr 계정 팔로우 필수!
                  2. @babyshark.kr 계정 태그하면 당첨확률 UP!
                  3. 게시글에 친구 태그!
                  시청인증 이벤트를 소문내주세요 :)`}
              </Text>
            </div>
          </ColumnFlexBox>
        </CenteredFlexBox>
        <Stacker margin="xl" />

        <TextWithBackground color="white" backgroundColor="#ff9b37" size="41px" borderRadius="30px" padding="12px 24px">
          매달 당첨자 발표!
        </TextWithBackground>
        <Stacker margin="large" />
        <CardContainer>
          <GiveawayCard imgSrc="/assets/event/CodingComputer.png">
            <Text size="24px" color="#be7d19">
              {'1등 (1명)\n'}
            </Text>
            <Text size="16px" color="#be7d19">
              {'아기상어\n코딩컴퓨터'}
            </Text>
          </GiveawayCard>
          <GiveawayCard imgSrc="/assets/event/OWDoll.svg">
            <Text size="24px" color="#be7d19">
              {'2등 (5명)\n'}
            </Text>
            <Text size="16px" color="#be7d19">
              {'올리와 윌리엄\n인형세트'}
            </Text>
          </GiveawayCard>
          <GiveawayCard imgSrc="/assets/event/Towel.png">
            <Text size="24px" color="#be7d19">
              {'3등 (20명)\n'}
            </Text>
            <Text size="16px" color="#be7d19">
              {'이쿠나 콜라보 자수\n미니 타월 5종 세트'}
            </Text>
          </GiveawayCard>
        </CardContainer>
        <Stacker margin="large" />
        <Stacker margin="xxl" />

        <GiveawayTable>
          <thead>
            <th></th>
            <th>이벤트 기간</th>
            <th>당첨 발표</th>
          </thead>
          <tbody>
            <tr>
              <td>1차</td>
              <td>5.30~6.30</td>
              <td>7/5</td>
            </tr>
            <tr>
              <td>2차</td>
              <td>7.1~7.31</td>
              <td>8/5</td>
            </tr>
            <tr>
              <td>3차</td>
              <td>8.1~8.31</td>
              <td>9/5</td>
            </tr>
          </tbody>
        </GiveawayTable>
        <Stacker margin="xl" />
        <CardContainer>
          <BorderlessGiveawayCard width="220px" imgSrc="/assets/event/Ticket.png">
            <Text size="24px" color="#be7d19" marks={['호텔숙박권']} markColor="#f5e39a">
              {`3차 이벤트 모두 참여했다면?\n추첨을 통해 호텔숙박권을!`}
            </Text>
            <Stacker margin="xs" />
            <Text size="14px" color="#be7d19">
              {'*제세공과금 본인부담'}
            </Text>
          </BorderlessGiveawayCard>
          <Stacker margin="medium" />
          <BorderlessGiveawayCard width="220px" imgSrc="/assets/event/KeyRing.svg">
            <Text size="24px" color="#be7d19" marks={['스티커+키링']} markColor="#f5e39a">
              {`인증에 한번이라도 참여했다면?\n추첨을 통해 스티커+키링을!`}
            </Text>
          </BorderlessGiveawayCard>
        </CardContainer>

        <SVGImg src="/assets/event/OllyWithScript.svg" width="500px" />
        <Stacker margin="xl" />

        <HighlightedText color="#509b32" size="24px">
          잠깐
        </HighlightedText>
        <HighlightedText color="#509b32" size="36px">
          {`아기상어 올리와 윌리엄의\n본방송을 놓쳤다면?`}
        </HighlightedText>
        <Stacker margin="large" />

        <CenteredFlexBox style={{ width: '80vw', justifyContent: 'space-between' }}>
          <SVGImg width="20vw" src="/assets/event/KidsLand.svg" />
          <SVGImg width="20vw" src="/assets/event/ChildrenLand.svg" />
          <SVGImg width="15vw" src="/assets/event/GemKids.svg" />
          <SVGImg width="12vw" src="/assets/event/CableTV.svg" />
        </CenteredFlexBox>
        <Stacker margin="large" />

        <HighlightedText color="#be7d19" size="36px">
          {`케이블/IPTV에서도\n만나볼 수 있어요!`}
        </HighlightedText>
        <Stacker margin="xxl" />
      </BackgroundDivEvent2>
    </>
  );
}

function EventContent3() {
  return <></>;
}

function CanDoItContent() {
  return (
    <>
      <TextWithBackground color="white" backgroundColor="#ff9b37" size="41px" borderRadius="30px" padding="12px 24px">
        How to DOO
      </TextWithBackground>
      <Stacker margin="large" />

      <Text
        align="center"
        size="24px"
        color="#112b8b"
        marks={['I can DOO it', 'Instagram & Tiktok']}
        markColor="#ff9b37"
        bold>
        {`Share your I can DOO it moments\non Instagram & Tiktok and \nwin FIN-TASTIC PRIZES`}
      </Text>
      <Stacker margin="large" />

      <Card backgroundColor="#faa71c" width="mobile" height="auto" borderColor="#faa71c">
        <DooItTable>
          <tbody>
            <tr>
              <td>1. Post</td>
              <td>
                your i can DOO it moments on
                <img
                  style={{ marginRight: '5px', marginTop: '5px' }}
                  width="75px"
                  src="/assets/icons/InstagramIcon.png"
                />
                <img style={{ marginRight: '5px', marginTop: '5px' }} width="75px" src="/assets/icons/TiktokIcon.png" />
              </td>
            </tr>
            <tr>
              <td>2. Tag</td>
              <td>@babysharkbrooklyn.official</td>
            </tr>
            <tr>
              <td>3. Hashtag</td>
              <td>#IcanDOOit #BabyShark #doodoodoodooodoodoo</td>
            </tr>
          </tbody>
        </DooItTable>
      </Card>
      <Stacker margin="xl" />

      <TextWithBackground color="white" backgroundColor="#ff9b37" size="41px" borderRadius="30px" padding="12px 24px">
        Prize
      </TextWithBackground>
      <Stacker margin="large" />

      <BackgroundDivEvent2>
        <PrizeWrapper backgroundColor="#fcfacf" width="mobile" height="auto" borderColor="#fcfacf">
          <PrizeCardContainer>
            <PrizeCard width="medium" height="medium" backgroundColor="white" borderColor="white">
              <CenteredFlexBox style={{ padding: '32px 0px' }}>
                <img src="/assets/event/ICanDooIt/pinkfongPlus.png" width="175px" />
              </CenteredFlexBox>
              <ColumnFlexBox style={{ backgroundColor: '#73bbff', width: '100%', height: '33%' }}>
                <Text size="24px" color="white">
                  App Subscription
                </Text>
                <Text size="14px" color="white">
                  1month
                </Text>
              </ColumnFlexBox>
            </PrizeCard>
            <PrizeCard width="medium" height="medium" backgroundColor="white" borderColor="white">
              <CenteredFlexBox style={{ padding: '25px 0px' }}>
                <img src="/assets/event/ICanDooIt/instagram.png" width="118px" />
              </CenteredFlexBox>
              <ColumnFlexBox style={{ backgroundColor: '#73bbff', width: '100%', height: '33%' }}>
                <Text size="24px" color="white">
                  Featured on
                </Text>
                <Text size="14px" color="white">
                  @babysharkbrooklyn.official
                </Text>
              </ColumnFlexBox>
            </PrizeCard>
          </PrizeCardContainer>
        </PrizeWrapper>
        <Stacker margin="xs" />

        <Text size="18px" color="#fa9f0e">
          - Winner Announcement: 9/2 (Fri)
        </Text>
        <Stacker margin="xl" />

        <HighlightedText color="white" size="36px" borderColor="#f9a71b">
          {`You can doo doo\ndoo doo doo doo it!`}
        </HighlightedText>
        <Stacker margin="large" />
        <CenteredFlexBox>
          <GradationButton
            startColor="#ff60a5"
            endColor="#f57db1"
            midColor="#f34c95"
            highlightColor="#ffb4cf"
            highlightOptions={{ width: '45px', height: '45px', top: '6px', left: '6px' }}
            size="large"
            onClick={() => window.open('https://fong.kr/homepagelink/')}>
            <Text color="white" size="36px" font="ManwhaGage">
              {`Post your\n#IcanDOOit moments NOW!`}
            </Text>
          </GradationButton>
          <ImageBackground
            src="/assets/event/ICanDooIt/Hand.png"
            alt="pointer"
            width="50px"
            bottom="-30px"
            left="-20px"
          />
        </CenteredFlexBox>
        <Stacker margin="xl" />
      </BackgroundDivEvent2>
    </>
  );
}

function EventContents({ currentEvent }: EventContentsProps) {
  const events = [
    <EventContent2 key="content2" />,
    <EventContent1 key="content1" />,
    <EventContent3 key="content3" />,
    <CanDoItContent key="content4" />,
    <EventContent3 key="content5" />,
  ];

  return events[currentEvent];
}

export default EventContents;
