import styled from '@emotion/styled';

const defaultHeight = '60px';
const defaultBorderRadius = '1rem';
const defaultVerticalPadding = '3rem';
const defaultBorderWidth = '0.5rem';

interface TabStyles {
  height?: string;
  selected?: boolean;
  index?: number;
}

const StyledTab = styled.button<TabStyles>`
  display: flex;
  height: ${(props) => props.height ?? defaultHeight};
  border-radius: ${defaultBorderRadius} ${defaultBorderRadius} 0 0;
  background-color: ${(props) => (props.selected ? props.theme.colors.tabActive : props.theme.colors.tabDefault)};
  color: ${(props) => props.theme.colors.realWhite};
  padding: 0 ${defaultVerticalPadding};
  align-items: center;
  justify-content: center;
  border: ${defaultBorderWidth} solid ${(props) => props.theme.colors.realWhite};
  font-size: 37.5px;
  :hover {
    border: ${defaultBorderWidth} solid ${(props) => props.theme.colors.realWhite};
  }
`;

export interface TabProps extends TabStyles {
  text: string;
  onChange?: (index: number) => void;
}

function StupidTab(props: TabProps) {
  function handleChangeIndex() {
    if (props.onChange !== undefined && props.index !== undefined) {
      props.onChange(props.index);
    }
  }

  return (
    <StyledTab {...(props as TabStyles)} onClick={() => handleChangeIndex()}>
      {props.text}
    </StyledTab>
  );
}

export default StupidTab;
