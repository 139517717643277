import './App.css';
import { Route, useHistory, useLocation } from 'react-router-dom';
import Home from 'pages/home/Home';
import Callback from 'callbacks/Callback';
import Shop from 'pages/shop/Shop';
import Activity from 'pages/activity/Activity';
import { CustomCursor } from 'UI';
import { isDesktop } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState } from 'recoil';
import { langState } from 'atoms/lang';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const [lang, setLang] = useRecoilState(langState);
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.code);
  }, [lang.code]);

  useEffect(() => {
    if (location.pathname.includes('/en') && lang.code !== 'en') {
      setLang({ code: 'en' });
      history.push(location.pathname.replace('/en', ''));
    }
  }, [location, lang.code]);

  return (
    <>
      {isDesktop && <CustomCursor />}
      <Route path="/" render={() => <Home tab={0} />} exact />
      <Route path="/desc" render={() => <Home tab={1} />} exact />
      <Route path="/event" render={() => <Home tab={2} />} />
      <Route path="/shop" component={Shop} />
      <Route path="/activity" component={Activity} />
      <Route path="/callback" component={Callback} />
      <ToastContainer />
    </>
  );
}

export default App;
