export function calculateStrokeTextCSS(steps: number, color: string, width = '5px') {
  let css = '';

  for (let i = 0; i < steps; i++) {
    const angle = (i * 2 * Math.PI) / steps;
    const cos = Math.round(10000 * Math.cos(angle)) / 10000;
    const sin = Math.round(10000 * Math.sin(angle)) / 10000;
    css += `calc(${width} * ${cos}) calc(${width} * ${sin}) 0 ${color}${steps - 1 !== i ? ',' : ''}`;
  }

  return css;
}

export function halfPixel(originalPx: string) {
  return Number(originalPx.replace('px', '')) / 2 + 'px';
}

export function doublePixel(originalPx: string) {
  return Number(originalPx.replace('px', '')) * 2 + 'px';
}
