import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { AbsoluteBox } from 'styled/AbsoluteBox';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { sizes } from 'theme/size';
import { theme } from 'theme/theme';
import { FadeInSVGImg, HighlightedText, Spacer, Stacker, Text } from 'UI';

const homePrimaryColor = '#0E294F';
const mainTextStyles = {
  size: '23px',
  lineHeight: '44px',
  color: homePrimaryColor,
};

const ResponsiveContainer = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  position: relative;
  gap: 18px;
  @media ${sizes.device.mobile} {
    flex-direction: column;
  }
`;

function Watch() {
  const [lang] = useRecoilState(langState);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.code);
  }, [lang.code]);

  return (
    <>
      <Spacer margin="125px" />

      <ResponsiveContainer>
        <AbsoluteBox style={{ left: '25px', top: '-150px' }}>
          <ReactSVG src="/assets/Bubble.svg" />
        </AbsoluteBox>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/Olly.svg" />
          <Stacker margin="small" />
        </ColumnFlexBox>

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.olly} borderColor="white">
            {t('home.desc.olly_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.olly_title')}
          </HighlightedText>

          <Text {...mainTextStyles}>{t('home.desc.olly_main')}</Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <ResponsiveContainer reverse>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/William.svg" />
        </ColumnFlexBox>
        <Stacker margin="xl" />

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.william} borderColor="white">
            {t('home.desc.william_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.william_title')}
          </HighlightedText>
          <Text {...mainTextStyles}>{t('home.desc.william_main')}</Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <ResponsiveContainer>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/MomShark.svg" />
        </ColumnFlexBox>
        <Stacker margin="xl" />

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.mom} borderColor="white">
            {t('home.desc.mom_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.mom_title')}
          </HighlightedText>
          <Text {...mainTextStyles}>{t('home.desc.mom_main')}</Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <ResponsiveContainer reverse>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/DaddyShark.svg" />
        </ColumnFlexBox>
        <Stacker margin="xl" />

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.daddy} borderColor="white">
            {t('home.desc.dad_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.dad_title')}
          </HighlightedText>
          <Text marks={['집 바로 아래에 딸린 치과를', '운영', '조금 충동적인 성격', '가정적인 편']} {...mainTextStyles}>
            {t('home.desc.dad_main')}
          </Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <ResponsiveContainer>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/GrandmaShark.svg" />
        </ColumnFlexBox>
        <Stacker margin="xl" />

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.grandma} borderColor="white">
            {t('home.desc.grandma_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.grandma_title')}
          </HighlightedText>
          <Text {...mainTextStyles}>{t('home.desc.grandma_main')}</Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <ResponsiveContainer reverse>
        <ColumnFlexBox>
          <FadeInSVGImg src="/assets/GrandpaShark.svg" />
        </ColumnFlexBox>
        <Stacker margin="xl" />

        <ColumnFlexBox>
          <HighlightedText size="48px" color={theme.colors.grandpa} borderColor="white">
            {t('home.desc.grandpa_highlight')}
          </HighlightedText>
          <Stacker margin="small" />
          <HighlightedText size="38px" color="white">
            {t('home.desc.grandpa_title')}
          </HighlightedText>
          <Text {...mainTextStyles}>{t('home.desc.grandpa_main')}</Text>
        </ColumnFlexBox>
      </ResponsiveContainer>
      <Spacer mb="160px" />

      <Stacker margin="xl" />
      <Stacker margin="xl" />
    </>
  );
}

export default Watch;
