import styled from '@emotion/styled';
import Wave from 'react-wavify';
import { theme } from 'theme/theme';

const WaveContainer = styled.div`
  width: 100%;
  pointer-events: none;
  margin-top: -90px;
  margin-bottom: -5px;
`;

function WaveAnimation() {
  return (
    <WaveContainer>
      <Wave
        fill={theme.colors.homeContent}
        options={{
          height: 70,
          amplitude: 13,
          speed: 0.2,
          points: 5,
        }}
      />
    </WaveContainer>
  );
}

export default WaveAnimation;
