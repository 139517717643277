import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { SVGImg } from 'styled/SVGImg';
import { sizes } from 'theme/size';
import Card from 'UI/atoms/Card';

interface GiveawayCardProps {
  imgSrc: string;
  text?: string;
  width?: string;
  children?: ReactNode;
}

const CardContainer = styled.div<{ color?: string }>`
  width: 20vw;
  height: 20vw;

  @media ${sizes.device.mobile} {
    width: 30vw;
    height: 30vw;
  }

  max-width: 300px;
  max-height: 300px;
  margin-right: 2vw;
  text-align: center;
  color: ${(props) => props.color ?? '#be7d19'};
  font-size: 22px;
  font-weight: 800;
  white-space: pre-line;
`;

const RoundCard = styled(Card)`
  border-radius: 50%;
  border: none;
  background: #e6dfe6;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  & > div > div > svg {
    height: auto;
  }
`;

const RoundedCard = styled(RoundCard)`
  border-radius: 20px;
  background: #fdfbe1;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  max-height: 250px;
  display: flex;
  & > div > div > svg {
    width: 180px;
    height: auto;
  }
`;

const Event1Card = styled(RoundCard)`
  border-radius: 20px;
  border: 4px #f4eef4 solid;
  background: white;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  max-height: 250px;
  display: flex;
  & > div > div > svg {
    height: auto;
  }
`;

const ResponsiveBorderlessCardContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  width: 20vw;
  justify-content: center;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  text-align: center;
  white-space: pre-line;
  span {
    color: ${(props) => props.color ?? '#be7d19'};
    font-size: 23px;
    font-weight: 800;
    flex: 1;
    text-align: center;
  }
  @media ${sizes.device.mobile} {
    width: 75vw;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    div:first-child {
      width: 40vw;
    }
    text-align: left;
    span {
      margin-left: 32px;
      text-align: left;
      font-size: 28px;
    }
  }
`;

const BorderlessCardContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  text-align: center;
  white-space: pre-line;
  height: 450px;
  span {
    font-weight: 800;
    flex: 1;
    text-align: center;
  }
`;

function GiveawayCard({ imgSrc, text, width = '80%', children }: GiveawayCardProps) {
  return (
    <CardContainer>
      <RoundCard width="full" height="full">
        {imgSrc.includes('svg') ? <SVGImg width={width} src={imgSrc} /> : <img width="100%" src={imgSrc} />}
      </RoundCard>
      {text}
      {children}
    </CardContainer>
  );
}

export function GiveawayCardEvent1({ imgSrc, text, width = '80%' }: GiveawayCardProps) {
  return (
    <ResponsiveBorderlessCardContainer color="black">
      <Event1Card width="full" height="full">
        <img src={imgSrc} height="200px" />
      </Event1Card>
      <span>{text}</span>
    </ResponsiveBorderlessCardContainer>
  );
}

export function BorderlessGiveawayCard({ imgSrc, children, width = '80%' }: GiveawayCardProps) {
  return (
    <BorderlessCardContainer color="black">
      <RoundedCard width="full" height="full">
        <img src={imgSrc} width={width} />
      </RoundedCard>
      {children}
    </BorderlessCardContainer>
  );
}

export default GiveawayCard;
