import styled from '@emotion/styled';
import { ReactNode } from 'react';

const MenuContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 1;
  background: ${(props) => props.theme.colors.realWhite};
  border-radius: 5px;
`;

interface DropDownMenuProps {
  children: ReactNode;
  visible?: boolean;
}

function DropDownMenu({ children, visible = false }: DropDownMenuProps) {
  return <MenuContainer visible={visible}>{children}</MenuContainer>;
}

export default DropDownMenu;
