import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ko'];

export const resources = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
};

i18n.use(initReactI18next).init({
  lng: 'ko',
  resources: resources,
  fallbackLng,
  detection: {
    checkWhitelist: true,
  },
  debug: true,
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false, // no need for react. it escapes by default
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
