import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { sizes } from 'theme/size';

const defaultHeight = '100px';

interface TabStyles {
  height?: string;
  color?: string;
  selected?: boolean;
  index?: number;
  font?: string;
}

const BasicTab = styled.button<TabStyles>`
  display: flex;
  height: ${(props) => props.height ?? defaultHeight};
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
  min-width: 100px;

  svg {
    margin-right: ${(props) => (props.selected ? '7px' : '0px')};
    @media ${sizes.device.mobile} {
      display: ${(props) => (props.selected ? 'none' : 'block')};
      margin-right: 0;
    }
  }
`;

const StyledTab = styled(BasicTab)`
  display: flex;
  height: ${(props) => props.height ?? defaultHeight};
  border-radius: 1.8rem 1.8rem 0 0;
  background-color: ${(props) => props.color ?? props.theme.colors.home};
  color: ${(props) => props.theme.colors.white};
  padding: 0 ${(props) => (props.selected ? '2rem' : '1rem')};
  align-items: center;
  justify-content: center;
  border: 0.2rem solid ${(props) => props.color ?? props.theme.colors.primary};
  font-size: 33px;
  :hover {
    border: 0.2rem solid ${(props) => props.theme.colors.secondary};
  }
  font-family: 'PinkfongBT';
`;

const StupidTab = styled(BasicTab)`
  display: flex;
  height: ${(props) => props.height ?? defaultHeight};
  border-radius: 1.8rem 1.8rem 0 0;
  background-color: ${(props) => (props.selected ? props.theme.colors.tabActive : props.theme.colors.tabDefault)};
  color: ${(props) => props.theme.colors.realWhite};
  padding: 0 15px;
  min-width: 220px;
  width: 23vw;
  align-items: center;
  justify-content: center;
  border: 1rem solid ${(props) => props.theme.colors.realWhite};
  border-bottom: 0;
  font-size: 30px;
  :hover {
    border: 1rem solid ${(props) => props.theme.colors.realWhite};
    border-bottom: 0;
  }
  font-family: ${(props) => props.font ?? 'ManwhaGage'};
`;

export interface TabProps extends TabStyles {
  text: string;
  icon?: string;
  stupid?: boolean;
  onChange?: (index: number) => void;
  onClick?: () => void;
}

function Tab(props: TabProps) {
  const [lang] = useRecoilState(langState);
  const tabFont = lang.code === 'ko' ? 'ManwhaGage' : 'PinkfongBT';

  function handleChangeIndex() {
    if (props.onChange !== undefined && props.onClick !== undefined) {
      props.onClick();
      props.onChange(props.index ?? 0);
    }
  }

  if (props.stupid === undefined || props.stupid === false) {
    return (
      <StyledTab {...(props as TabStyles)} onClick={() => handleChangeIndex()}>
        {props.icon && <ReactSVG src={`/assets/icons/${props.icon}.svg`} />}
        {props.selected && props.text}
      </StyledTab>
    );
  } else {
    return (
      <StupidTab {...(props as TabStyles)} onClick={() => handleChangeIndex()} font={tabFont}>
        {props.text}
      </StupidTab>
    );
  }
}

export default Tab;
