import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { ResponsiveHeader } from 'styled/Responsive';

const StyledHeader = styled(ResponsiveHeader)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.colors.realWhite};
  padding-top: 20px;

  & > div:first-of-type {
    flex: 0;
    margin-left: 3vw;
  }

  & > button:last-child {
    flex: 0;
    margin-right: 2vw;
  }
`;

interface HeaderProps {
  children: ReactNode;
}

function Header({ children }: HeaderProps) {
  return <StyledHeader>{children}</StyledHeader>;
}

export default Header;
