import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { sizes } from 'theme/size';

const defaultColor = '#005ae6';

interface ButtonStyles {
  size: 'small' | 'medium' | 'large';
  startColor?: string;
  midColor?: string;
  endColor?: string;
  highlightColor?: string;
  color?: string;
}

interface HighlightOptions {
  width: string;
  height: string;
  top: string;
  left: string;
}

interface GradationButtonProps extends ButtonStyles {
  onClick?: () => void;
  children: ReactNode;
  highlightOptions?: HighlightOptions;
}

const buttonPaddings = {
  small: '4px 10px',
  medium: '6px 16px',
  large: '8px 28px',
};

const StyledButton = styled.button<ButtonStyles>`
  border-radius: 100px;
  min-width: 64px;
  padding: ${(props) => buttonPaddings[props.size]};
  @media ${sizes.device.mobile} {
    width: 100%;
  }
  background-image: linear-gradient(
    to right,
    ${(props) => props.startColor ?? defaultColor} 0%,
    ${(props) => props.midColor ?? defaultColor} 51%,
    ${(props) => props.endColor ?? defaultColor} 100%
  );
  border: none;
  color: ${(props) => props.color ?? props.theme.colors.white};
  font-weight: 800;
  font-size: 18px;
  transition: 0.5s;
  background-size: 200% auto;
  position: relative;
  cursor: pointer;
  box-shadow: 0 5px 5px ${(props) => props.endColor ?? defaultColor};

  &:hover {
    background-position: right center;
  }
`;

const HighlightEffect = styled.div<{ color: string; highlightOptions?: HighlightOptions }>`
  clip-path: polygon(
    27.500000000000004% 22.499999999999996%,
    25.758286370337235% 22.623847354670723%,
    24.038786645218174% 23.00234296999083%,
    22.36496893747538% 23.643442987386855%,
    20.761295853349672% 24.551628546819316%,
    19.25278789773418% 25.727735941888678%,
    17.86456665099663% 27.168850148405895%,
    16.62138646192635% 28.86826423818928%,
    15.547163671236234% 30.815505942202883%,
    14.664512474969115% 32.9964313525941%,
    13.994296455035823% 35.39338447636266%,
    13.55520454631754% 37.98542009901121%,
    13.36335978108658% 40.74858620886711%,
    13.431968560051615% 43.6562610952155%,
    13.771017456401438% 46.679539187976374%,
    14.387023679041775% 49.78765877365274%,
    15.282844320706104% 52.94846391975601%,
    16.457548415032058% 56.12889228343489%,
    17.906354645265516% 59.2954799823312%,
    19.620636308778245% 62.414874376414744%,
    21.587993869976785% 65.45434545510584%,
    23.79239415400797% 68.38228654738737%,
    26.21437396967409% 71.1686952733993%,
    28.831304726600713% 73.78562603032591%,
    31.61771345261263% 76.20760584599203%,
    34.545654544894155% 78.41200613002322%,
    37.58512562358526% 80.37936369122176%,
    40.7045200176688% 82.09364535473449%,
    43.87110771656513% 83.54245158496795%,
    47.051536080244006% 84.71715567929391%,
    50.21234122634725% 85.61297632095823%,
    53.32046081202361% 86.22898254359856%,
    56.343738904784495% 86.56803143994838%,
    59.25141379113289% 86.63664021891343%,
    62.01457990098879% 86.44479545368247%,
    64.60661552363732% 86.00570354496418%,
    67.0035686474059% 85.33548752503089%,
    69.18449405779711% 84.45283632876377%,
    71.13173576181072% 83.37861353807365%,
    72.83114985159408% 82.13543334900339%,
    74.27226405811132% 80.74721210226583%,
    75.44837145318068% 79.23870414665033%,
    76.35655701261314% 77.63503106252463%,
    76.99765703000917% 75.96121335478182%,
    77.37615264532928% 74.24171362966277%,
    77.5% 72.5%,
    77.38040072693158% 70.75813802405882%,
    77.03151760629831% 69.03641888306771%,
    76.47014124460962% 67.35303075363313%,
    75.7153145456906% 65.7237794483103%,
    74.7879225201713% 64.16186339804237%,
    73.71025561002169% 62.67770695364094%,
    72.50555517451997% 61.27885481148836%,
    71.19755008488765% 59.96992914419407%,
    69.80999350102833% 58.75264976402964%,
    68.36620885314821% 57.6259163901481%,
    66.88865382472503% 56.585950854468834%,
    65.39851073596434% 55.62649588987516%,
    63.915311166502576% 54.73906602133038%,
    62.45660194381481% 53.91324504763768%,
    61.037658773652744% 53.13702367904179%,
    59.67125281763915% 52.39717010173071%,
    58.367474449997346% 51.6796255900608%,
    57.13361727139173% 50.96991679369974%,
    55.97412424566155% 50.25357599949821%,
    54.890596578628504% 49.516560513147155%,
    53.88186470159747% 48.74566232656321%,
    52.94411948027654% 47.928899433046944%,
    52.071100566953056% 47.05588051972346%,
    51.2543376734368% 46.118135298402535%,
    50.48343948685285% 45.109403421371496%,
    49.74642400050179% 44.025875754338465%,
    49.030083206300276% 42.86638272860828%,
    48.32037440993921% 41.63252555000266%,
    47.6028298982693% 40.32874718236086%,
    46.862976320958225% 38.96234122634725%,
    46.08675495236232% 37.543398056185175%,
    45.26093397866963% 36.08468883349741%,
    44.373504110124856% 34.60148926403568%,
    43.41404914553117% 33.11134617527499%,
    42.37408360985191% 31.6337911468518%,
    41.247350235970366% 30.190006498971698%,
    40.030070855805945% 28.802449915112348%,
    38.72114518851164% 27.494444825480038%,
    37.322293046359064% 26.289744389978313%,
    35.83813660195763% 25.212077479828697%,
    34.27622055168971% 24.284685454309397%,
    32.64696924636687% 23.529858755390375%,
    30.96358111693231% 22.968482393701695%,
    29.241861975941195% 22.619599273068413%,
    27.500000000000007% 22.499999999999996%,
    25.75828637033724% 22.623847354670723%,
    24.038786645218174% 23.00234296999083%,
    22.36496893747537% 23.643442987386855%,
    20.761295853349672% 24.551628546819337%,
    19.25278789773417% 25.727735941888678%,
    17.864566650996622% 27.16885014840592%,
    16.621386461926356% 28.86826423818926%,
    15.547163671236241% 30.815505942202883%,
    14.664512474969115% 32.99643135259409%,
    13.994296455035823% 35.39338447636266%,
    13.55520454631754% 37.98542009901121%,
    13.36335978108658% 40.74858620886711%,
    13.431968560051622% 43.65626109521553%,
    13.771017456401438% 46.67953918797639%,
    14.387023679041775% 49.78765877365275%,
    15.282844320706097% 52.94846391975599%,
    16.457548415032043% 56.12889228343486%,
    17.906354645265516% 59.295479982331194%,
    19.620636308778234% 62.41487437641473%,
    21.587993869976774% 65.45434545510582%,
    23.79239415400797% 68.38228654738737%,
    26.214373969674103% 71.1686952733993%,
    28.831304726600713% 73.78562603032591%,
    31.617713452612644% 76.20760584599205%,
    34.54565454489415% 78.4120061300232%,
    37.585125623585284% 80.37936369122178%,
    40.704520017668784% 82.09364535473448%,
    43.87110771656515% 83.54245158496795%,
    47.05153608024399% 84.7171556792939%,
    50.21234122634723% 85.61297632095823%,
    53.320460812023626% 86.22898254359856%,
    56.34373890478445% 86.56803143994838%,
    59.25141379113292% 86.63664021891343%,
    62.01457990098877% 86.44479545368247%,
    64.60661552363734% 86.00570354496418%,
    67.0035686474059% 85.33548752503089%,
    69.18449405779712% 84.45283632876377%,
    71.13173576181072% 83.37861353807365%,
    72.83114985159408% 82.13543334900339%,
    74.27226405811132% 80.74721210226583%,
    75.44837145318067% 79.23870414665035%,
    76.35655701261317% 77.63503106252463%,
    76.99765703000917% 75.96121335478185%,
    77.37615264532928% 74.24171362966277%,
    77.5% 72.50000000000001%,
    77.38040072693158% 70.75813802405881%,
    77.03151760629832% 69.03641888306771%,
    76.47014124460965% 67.35303075363316%,
    75.7153145456906% 65.72377944831028%,
    74.78792252017132% 64.16186339804239%,
    73.71025561002166% 62.67770695364094%,
    72.50555517451998% 61.27885481148837%,
    71.19755008488764% 59.969929144194055%,
    69.80999350102832% 58.75264976402965%,
    68.36620885314821% 57.62591639014809%,
    66.88865382472503% 56.58595085446884%,
    65.39851073596435% 55.62649588987517%,
    63.91531116650259% 54.73906602133038%,
    62.45660194381483% 53.913245047637695%,
    61.03765877365274% 53.137023679041775%,
    59.67125281763914% 52.397170101730715%,
    58.36747444999734% 51.67962559006079%,
    57.13361727139173% 50.969916793699745%,
    55.97412424566153% 50.25357599949821%,
    54.890596578628504% 49.51656051314716%,
    53.881864701597486% 48.74566232656323%,
    52.94411948027653% 47.92889943304696%,
    52.07110056695306% 47.05588051972347%,
    51.25433767343679% 46.118135298402535%,
    50.48343948685285% 45.10940342137151%,
    49.74642400050178% 44.025875754338465%,
    49.03008320630028% 42.86638272860827%,
    48.3203744099392% 41.632525550002654%,
    47.6028298982693% 40.328747182360864%,
    46.86297632095824% 38.962341226347284%,
    46.08675495236233% 37.54339805618517%,
    45.260933978669634% 36.08468883349744%,
    44.37350411012485% 34.60148926403565%,
    43.41404914553118% 33.11134617527499%,
    42.3740836098519% 31.633791146851788%,
    41.24735023597037% 30.190006498971698%,
    40.03007085580593% 28.802449915112348%,
    38.72114518851165% 27.494444825480034%,
    37.322293046359086% 26.289744389978335%,
    35.838136601957636% 25.212077479828697%,
    34.27622055168973% 24.284685454309418%,
    32.64696924636685% 23.529858755390375%,
    30.963581116932314% 22.968482393701695%,
    29.24186197594118% 22.619599273068413%
  );
  overflow: hidden;
  background: ${(props) => props.color};
  width: ${(props) => props.highlightOptions?.width ?? '30px'};
  height: ${(props) => props.highlightOptions?.height ?? '30px'};
  opacity: 0.4;
  position: absolute;
  top: ${(props) => props.highlightOptions?.top ?? '4px'};
  transform: rotate(90deg);
  left: ${(props) => props.highlightOptions?.left ?? '4px'};
`;

function GradationButton(props: GradationButtonProps) {
  return (
    <StyledButton {...props}>
      <HighlightEffect highlightOptions={props.highlightOptions} color={props.highlightColor ?? 'transparent'} />
      {props.children}
    </StyledButton>
  );
}

export default GradationButton;
