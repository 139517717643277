import styled from '@emotion/styled';
import { ReactNode } from 'react';
import TextWithBackground from 'UI/molecules/TextWithBackground';

const decoWidth = '12px';

const LeftDecoration = styled.div<{ color: string }>`
  :before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -25px;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 25px solid transparent;
    left: -${decoWidth};
    border-top: 0;
    border-left: 0;
    border-right: ${decoWidth} solid ${(props) => props.color};
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 25px solid transparent;
    left: -${decoWidth};
    border-bottom: 0;
    border-left: 0;
    border-right: ${decoWidth} solid ${(props) => props.color};
  }
`;

const RightDecoration = styled.div<{ color: string }>`
  :before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -25px;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 25px solid transparent;
    right: -${decoWidth};
    border-top: 0;
    border-right: 0;
    border-left: ${decoWidth} solid ${(props) => props.color};
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 25px solid transparent;
    right: -${decoWidth};
    border-bottom: 0;
    border-right: 0;
    border-left: ${decoWidth} solid ${(props) => props.color};
  }
`;

const CrushedBoxContainer = styled.div`
  position: relative;
`;

interface CrushedBoxTextProps {
  color: string;
  children: ReactNode;
}

function CrushedBoxText(props: CrushedBoxTextProps) {
  return (
    <CrushedBoxContainer>
      <TextWithBackground color="white" size="33px" height="38px" font="PinkfongBT" backgroundColor={props.color}>
        {props.children}
      </TextWithBackground>
      <LeftDecoration color={props.color} />
      <RightDecoration color={props.color} />
    </CrushedBoxContainer>
  );
}

export default CrushedBoxText;
