import { ReactSVG } from 'react-svg';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';

interface CenteredSVGProps {
  src: string;
}

function CenteredSVG({ src }: CenteredSVGProps) {
  return (
    <CenteredFlexBox>
      <ReactSVG src={src} />
    </CenteredFlexBox>
  );
}

export default CenteredSVG;
