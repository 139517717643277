import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { sizes } from 'theme/size';
import { langState } from 'atoms/lang';

const StyledButton = styled.button`
  & > span {
    margin-left: 5%;
    font-size: 20px;
    color: #c7c7c7;
    @media ${sizes.device.mobile} {
      display: none;
    }
  }

  border-radius: 50%;
  background-color: transparent;
  border: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin-bottom: 5px;
`;

function LanguageButton() {
  // eslint-disable-next-line no-unused-vars
  const [lang, setLang] = useRecoilState(langState);

  const changeLang = (lang: string) => {
    setLang({ code: lang });
  };

  return (
    <StyledButton>
      <ReactSVG src="/assets/icons/GlobalIcon.svg" onClick={() => changeLang('ko')} />
    </StyledButton>
  );
}

export default LanguageButton;
