import styled from '@emotion/styled';
import ResponsiveYoutube from 'UI/atoms/ResponsiveYoutube';
import SVGBackground from 'UI/atoms/SVGBackground';
import CardWithPropImg from 'UI/molecules/CardWithPropImg';

const CardContainer = styled.div`
  position: relative;
`;

function HomeEventCard() {
  return (
    <CardContainer>
      <CardWithPropImg>
        <ResponsiveYoutube src="https://www.youtube.com/embed/yUe9pPRAoWI" />
      </CardWithPropImg>
      <SVGBackground src="/assets/GMandGPSharks.svg" bottom="-200px" left="-90px" />
    </CardContainer>
  );
}

export default HomeEventCard;
