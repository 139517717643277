import { atom } from 'recoil';

export type User = {
  userId: number;
  userName: string;
  email: string;
  isActive: boolean;
  phone: string;
};

export const userState = atom<User>({
  key: 'userState',
  default: {
    userId: 0,
    userName: '',
    email: '',
    isActive: false,
    phone: '',
  },
});
