import { RouteComponentProps, useHistory } from 'react-router-dom';
import { parse, ParsedUrlQuery } from 'querystring';
import decodeJWT from 'jwt-decode';
import { useRecoilState } from 'recoil';
import { User, userState } from 'atoms/user';
import camelcaseKeys from 'camelcase-keys';

const Callback = ({ location }: RouteComponentProps) => {
  const queryParams: ParsedUrlQuery = parse(location.search.replace('?', ''));
  const userInfo: User = decodeJWT(JSON.stringify(queryParams.access_token));
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const history = useHistory();

  /*
    본 데이터 or jwt session storage에 담고 exp 비교하시어 사용하시거나 하면 될듯 합니다.
    exp 만료 시 밀어버리고. 본 callback 에서 확인하신 후, 다른 page로 redirect 시키면 될듯합니다.
    따로 varify lambda를 만들어두진 않았습니다.
    보안상 원하시면 jwt 확실한 데이터인가? 에 대한 verify lambda를 파드리겠습니다. - bigdeal
  */
  setUser(camelcaseKeys(userInfo));
  history.push('/');
  return <></>;
};

export default Callback;
