import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { SVGImg } from 'styled/SVGImg';
import {
  GradationButton,
  Text,
  Stacker,
  Spacer,
  ImageBackground,
  HighlightedText,
  TextWithBackground,
  CanDooItCard,
} from 'UI';
import HomeEmbedCard from 'UI/organisms/HomeEmbedCard';
import EventContents from './event/EventContents';

const ButtonHighlightColor = '#009bf9';

const EventContent = styled.div`
  position: relative;
  margin-top: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 944px; // bg img size
`;

const EventButtonContainer = styled(CenteredFlexBox)`
  display: flex;
  width: 100%;
  padding: 0px 10px;
`;

const EventButton = styled(GradationButton)`
  margin-right: 1.5vw;
  font-weight: 800;
`;

function Event2() {
  return (
    <>
      <Stacker margin="large" />
      <ReactSVG src="/assets/TitleLogo.svg" />
      <Stacker margin="xl" />

      <SVGImg src="/assets/WatchEventText.svg" width="700px" />

      <Text color="white" size="35px" marks={['기간 2022.05.30 ~ 2022.08.31']}>
        {'기간 2022.05.30 ~ 2022.08.31'}
      </Text>
      <Stacker margin="xl" />
      <img src="/assets/event/SuitShark.png" width="400px" />
      <Stacker margin="medium" />

      <TextWithBackground backgroundColor="#0082FF" size="41px" color="white">
        더 재미있게 돌아온
      </TextWithBackground>
      <Stacker margin="xs" />
      <TextWithBackground backgroundColor="#0082FF" size="41px" color="white">
        아기상어 올리와 윌리엄✨
      </TextWithBackground>

      <Stacker margin="medium" />

      <HighlightedText size="41px" borderColor="#0082FF" color="white">
        <HighlightedText size="41px" borderColor="#0082FF" color="#FFFE87">
          시청 인증
        </HighlightedText>
        {`하고 `}
        <HighlightedText size="41px" borderColor="#0082FF" color="#FFFE87">
          선물
        </HighlightedText>
        {` 받자!`}
      </HighlightedText>
    </>
  );
}

function Event1() {
  return (
    <>
      <Stacker margin="xl" />

      <TextWithBackground size="41px" color="white">
        당신은 이제부터 해양생물입니다.
      </TextWithBackground>
      <Stacker margin="medium" />
      <HighlightedText borderColor="#FD7D01" size="52px" color="white">
        나와 찰떡궁합
      </HighlightedText>
      <Stacker margin="small" />
      <HighlightedText borderColor="#2126BD" size="52px" color="white">
        해양생물 캐릭터는?
      </HighlightedText>

      <Stacker margin="large" />
      <CenteredFlexBox>
        <HomeEmbedCard />
      </CenteredFlexBox>
    </>
  );
}

function Event3() {
  return (
    <>
      <Stacker margin="xxl" />
      <SVGImg src="/assets/ComingSoon.svg" />
    </>
  );
}

function EventCanDooIt() {
  return (
    <>
      <Stacker margin="xxl" />
      <img src="/assets/event/ICanDooIt/ICanDooIt.png" width="60%" style={{ marginLeft: '-45px' }} />
      <Stacker margin="medium" />

      <TextWithBackground color="white" backgroundColor="#ff9b37" size="41px" borderRadius="30px" padding="12px 24px">
        AUG 1~31
      </TextWithBackground>
      <Stacker margin="xl" />

      <HighlightedText size="38px" color="white" borderColor="#112b8b">
        {`Share on `}
        <HighlightedText size="38px" color="#ffe364" borderColor="#112b8b">
          Instagram & Tiktok
        </HighlightedText>
      </HighlightedText>
      <Stacker margin="xs" />
      <HighlightedText size="38px" color="white" borderColor="#112b8b">
        {`what you can `}
        <HighlightedText size="38px" color="#ffe364" borderColor="#112b8b">
          DOO DOO DOO~
        </HighlightedText>
      </HighlightedText>
      <Stacker margin="small" />

      <CanDooItCard />
    </>
  );
}

function EventCommingSoon() {
  return (
    <>
      <Stacker margin="xxl" />
      <SVGImg src="/assets/ComingSoon.svg" />
    </>
  );
}

function Event() {
  const [lang] = useRecoilState(langState);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);

  const eventNames = [t('home.event.challenge'), t('home.event.viewing'), t('home.event.coupon')];

  useEffect(() => {
    i18n.changeLanguage(lang.code);
  }, [lang.code]);

  const [currentTab, setCurrentTab] = useState(0);
  const events = [
    <Event2 key="event-2" />,
    <Event1 key="event-1" />,
    <Event3 key="event-3" />,
    <EventCanDooIt key="event-4" />,
    <EventCommingSoon key="event-5" />,
  ];

  useEffect(() => {
    if (location.pathname.includes('/2')) {
      setCurrentTab(1);
    }
    if (location.pathname.includes('/content')) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location]);

  if (lang.code === 'en' && currentTab !== 3) {
    setCurrentTab(3);
  }

  return (
    <>
      <EventButtonContainer ref={ref}>
        {lang.code !== 'en' &&
          eventNames.map((eventName, index) => {
            return (
              <EventButton
                key={`EventButton-${index}`}
                startColor="#0078f5"
                endColor="#005dec"
                midColor="#0088ec"
                highlightColor={ButtonHighlightColor}
                size="large"
                onClick={() => setCurrentTab(index)}>
                <Text color="white" size="22px">
                  {eventName}
                </Text>
              </EventButton>
            );
          })}
      </EventButtonContainer>
      <Stacker margin="xl" />

      <ColumnFlexBox>{events[currentTab]}</ColumnFlexBox>
      <Stacker margin="xs" />

      <EventContent>
        <ImageBackground src="/assets/backgrounds/SandBG.png" alt="Event Page Sand Background" top="0px" />
        <Spacer mb="16vw" />
        <EventContents currentEvent={currentTab} />
      </EventContent>
    </>
  );
}

export default Event;
