import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Card } from 'UI';

interface CardWithBackgroundProps {
  width?: string;
  height?: string;
  borderColor?: string;
  borderRadius?: string;
  children: ReactNode;
}

const BackgroundContainer = styled.div`
  padding: 30px;
  position: relative;
`;

const CardContainer = styled.div`
  position: relative;
`;

const TLImage = styled.img`
  position: absolute;
  top: -62px;
  left: -70px;
  width: 125px;
`;

const BRImage = styled.img`
  position: absolute;
  bottom: -27px;
  right: -83.5px;
  width: 155px;
`;

// 크기를 오버라이드 하는 카드 스타일
const BigCard = styled(Card)<{ cardWidth: string; cardHeight: string; borderRadius: string }>`
  max-width: 1000px;
  min-width: 400px;
  width: ${(props) => props.cardWidth};
  height: ${(props) => props.cardHeight};
  border-width: 15px;
  border-radius: ${(props) => props.borderRadius ?? '20px'};
`;

function CardWithPropImg({
  borderColor = '#2757ae',
  width = '70vw',
  height = '100%',
  borderRadius = '20px',
  children,
}: CardWithBackgroundProps) {
  return (
    <BackgroundContainer>
      <CardContainer>
        <BigCard
          cardWidth={width}
          width="full"
          height="full"
          cardHeight={height}
          borderColor={borderColor}
          borderRadius={borderRadius}>
          {children}
        </BigCard>
        <TLImage src="/assets/SeaProp1.png" />
        <BRImage src="/assets/SeaProp2.png" />
      </CardContainer>
    </BackgroundContainer>
  );
}

export default CardWithPropImg;
