import styled from '@emotion/styled';
import React, { FormEvent, ReactElement, useState, cloneElement, useEffect } from 'react';
import { TabProps } from 'UI/atoms/Tab';

const StyledTabs = styled.nav<{ width?: string }>`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width ?? '100%'};
`;

export interface TabsProps {
  children: ReactElement<TabProps>[];
  value: number;
  width?: string;
  onChange?: () => void;
}

function Tabs(props: TabsProps) {
  const [currentIndex, setCurrentIndex] = useState(props.value);

  useEffect(() => {
    setCurrentIndex(props.value);
  }, [props.value]);

  function onChangeIndex(index: number) {
    setCurrentIndex(index);
  }

  function handleChange(e: FormEvent<HTMLElement>) {
    if (props.onChange !== undefined) {
      props.onChange();
    }
  }

  return (
    <StyledTabs width={props.width} onChange={(e) => handleChange(e)}>
      {props.children.map((tab, index) => {
        if (React.isValidElement(tab)) {
          const selected = currentIndex === index;
          return cloneElement(tab, {
            ...tab.props,
            onChange: onChangeIndex,
            selected: selected,
            index: index,
          });
        }
        return tab;
      })}
    </StyledTabs>
  );
}

export default Tabs;
