import styled from '@emotion/styled';

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  & iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface ResponsiveYoutubeProps {
  src: string;
}

function ResponsiveYoutube({ src }: ResponsiveYoutubeProps) {
  return (
    <EmbedContainer>
      <iframe src={src} frameBorder="0" allowFullScreen />
    </EmbedContainer>
  );
}

export default ResponsiveYoutube;
