import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import SVGBackground from 'UI/atoms/SVGBackground';
import CardWithPropImg from 'UI/molecules/CardWithPropImg';

const CardContainer = styled.div`
  position: relative;
`;

function HomeEmbedCard() {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });

  return (
    <CardContainer>
      <CardWithPropImg width="545px" height="850px" borderColor="white" borderRadius="45px">
        <iframe
          src="https://doda.app/embed/quiz/Y2kpD6zbIB"
          height="850"
          width="550"
          allowFullScreen
          style={{ border: 'none' }}
        />
      </CardWithPropImg>
      {!isMobile && (
        <>
          <SVGBackground src="/assets/event/MomShark.png" top="50px" right="-230px" />
          <SVGBackground src="/assets/event/DadShark.png" bottom="0px" left="-170px" />
        </>
      )}
    </CardContainer>
  );
}

export default HomeEmbedCard;
