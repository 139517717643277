interface Toy {
  number: number;
  name: string;
  tag: string;
  url: string;
  price: number;
  imagePath: string;
}

const baseDir = 'assets/shop/';

export const toys: Toy[] = [
  {
    number: 1,
    name: '아기상어&윌리엄 캐릭터인형',
    tag: 'Best',
    url: 'https://store.pinkfong.com/704/',
    price: 30000,
    imagePath: `${baseDir}Best/1.아기상어&윌리엄 캐릭터인형.jpeg`,
  },
  {
    number: 2,
    name: '아기상어 알파벳 버스',
    tag: 'Best',
    url: 'https://store.pinkfong.com/668/',
    price: 60000,
    imagePath: `${baseDir}Best/2.아기상어 알파벳 버스.jpeg`,
  },
  {
    number: 3,
    name: '상어가족 멜로디 패드',
    tag: 'Best',
    url: 'https://store.pinkfong.com/435/',
    price: 34000,
    imagePath: `${baseDir}Best/3.상어가족 멜로디 패드.jpeg`,
  },
  {
    number: 1,
    name: '말하고 노래하는 아기상어 양치놀이',
    tag: 'Toy',
    url: 'https://store.pinkfong.com/417/',
    price: 42000,
    imagePath: `${baseDir}Toy/1.말하고 노래하는 아기상어 양치놀이.jpeg`,
  },
  {
    number: 2,
    name: '아기상어 멜로디 청소기',
    tag: 'Toy',
    url: 'https://store.pinkfong.com/629/',
    price: 50000,
    imagePath: `${baseDir}Toy/2.아기상어 멜로디 청소기.jpeg`,
  },
  {
    number: 3,
    name: '워터파크 낚시놀이+모래놀이 세트',
    tag: 'Toy',
    url: 'https://store.pinkfong.com/599/',
    price: 49900,
    imagePath: `${baseDir}Toy/3.워터파크 낚시놀이+모래놀이 세트.jpeg`,
  },
  {
    number: 4,
    name: '상어가족을 찾아라! 도미노게임',
    tag: 'Toy',
    url: 'https://store.pinkfong.com/669/',
    price: 16000,
    imagePath: `${baseDir}Toy/4.상어가족을 찾아라! 도미노게임.jpeg`,
  },
  {
    number: 5,
    name: '핑크퐁 아기상어 미니 낚시놀이',
    tag: 'Toy',
    url: 'https://store.pinkfong.com/544/',
    price: 10000,
    imagePath: `${baseDir}Toy/5.핑크퐁 아기상어 미니 낚시놀이.jpeg`,
  },
  {
    number: 1,
    name: '핑크퐁 아기상어 드럼 사운드북',
    tag: 'Book',
    url: 'https://store.pinkfong.com/533/',
    price: 27000,
    imagePath: `${baseDir}Book/1.핑크퐁 아기상어 드럼 사운드북.jpeg`,
  },
  {
    number: 2,
    name: '상어가족 사운드북',
    tag: 'Book',
    url: 'https://store.pinkfong.com/212/',
    price: 14000,
    imagePath: `${baseDir}Book/2.상어가족 사운드북.jpeg`,
  },
  {
    number: 3,
    name: '아기상어 스티커 색칠북 시리즈',
    tag: 'Book',
    url: 'https://store.pinkfong.com/456/',
    price: 8200,
    imagePath: `${baseDir}Book/3.아기상어 스티커 색칠북 시리즈.jpeg`,
  },
  {
    number: 4,
    name: '상어가족 워터펜',
    tag: 'Book',
    url: 'https://store.pinkfong.com/219/',
    price: 12000,
    imagePath: `${baseDir}Book/4.상어가족 워터펜.jpeg`,
  },
  {
    number: 1,
    name: '아기상어 사운드 애착인형',
    tag: 'Plush',
    url: 'https://store.pinkfong.com/694/',
    price: 22000,
    imagePath: `${baseDir}Plush/1.아기상어 사운드 애착인형.jpeg`,
  },
  {
    number: 2,
    name: '아기상어 안전벨트 인형',
    tag: 'Plush',
    url: 'https://store.pinkfong.com/641/',
    price: 22000,
    imagePath: `${baseDir}Plush/2.아기상어 안전벨트 인형.jpeg`,
  },
  {
    number: 3,
    name: '핑크퐁 아기상어 꿀잠인형',
    tag: 'Plush',
    url: 'https://store.pinkfong.com/445/',
    price: 28000,
    imagePath: `${baseDir}Plush/3.핑크퐁 아기상어 꿀잠인형.jpeg`,
  },
  {
    number: 4,
    name: '키링 인형 6종 시리즈',
    tag: 'Plush',
    url: 'https://store.pinkfong.com/661/',
    price: 12000,
    imagePath: `${baseDir}Plush/4.키링 인형 6종 시리즈.jpeg`,
  },
];

export const toysEn: Toy[] = [
  {
    number: 1,
    name: 'Sing & Swing Baby Shark & William',
    tag: 'Best',
    url: 'https://www.amazon.com/Baby-Sharks-Swing-Musical-Plush/dp/B08T6GFG3S?ref_=ast_sto_dp',
    price: 29.99,
    imagePath: `${baseDir}Best/1_Sing & Swing Baby Shark & William.jpg`,
  },
  {
    number: 2,
    name: 'Pinkfong Baby Shark ABC Alphabet Bus',
    tag: 'Best',
    url: 'https://www.amazon.com/Pinkfong-Baby-Shark-Official-Alphabet/dp/B07WFHRZ55?ref_=ast_sto_dp&th=1&psc=1',
    price: 49.99,
    imagePath: `${baseDir}Best/2_Pinkfong Baby Shark ABC Alphabet Bus.jpg`,
  },
  {
    number: 3,
    name: 'Pinkfong Baby Shark Melody Pad',
    tag: 'Best',
    url: 'https://www.amazon.com/Pinkfong-Baby-Shark-Melody-Pad/dp/B07KWYXH3J?ref_=ast_sto_dp&th=1&psc=1',
    price: 39.99,
    imagePath: `${baseDir}Best/3_Pinkfong Baby Shark Melody Pad.jpg`,
  },
  {
    number: 1,
    name: 'Melody Shape Sorter',
    tag: 'Toy',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Shark-Melody-Sorter/dp/B07NJPKDM3?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Toy/1_Melody Shape Sorter.jpg`,
  },
  {
    number: 2,
    name: 'Melody Walker',
    tag: 'Toy',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Shark-Melody-Walker/dp/B07NJQ9LGT?ref_=ast_sto_dp&th=1&psc=1',
    price: 39.99,
    imagePath: `${baseDir}Toy/2_Melody Walker.jpg`,
  },
  {
    number: 3,
    name: 'Smartphone',
    tag: 'Toy',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Baby-Shark-Smartphone/dp/B07NH3GBQ7?ref_=ast_sto_dp&th=1&psc=1',
    price: 9.99,
    imagePath: `${baseDir}Toy/3_Smartphone.jpg`,
  },
  {
    number: 4,
    name: 'Tablet',
    tag: 'Toy',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Baby-Shark-Tablet/dp/B07NH1PVL1?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Toy/4_Tablet.jpg`,
  },
  {
    number: 5,
    name: 'Step & Sing Piano Dance Mat',
    tag: 'Toy',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Baby-Shark-Dance/dp/B08CTS9J22?ref_=ast_sto_dp',
    price: 19.99,
    imagePath: `${baseDir}Toy/5_Step & Sing Piano Dance Mat.jpg`,
  },
  {
    number: 1,
    name: 'Pinkfong Baby Shark Sing-Alongs Sound Book',
    tag: 'Book',
    url: 'https://www.amazon.com/Pinkfong-Baby-Shark-Sing-Alongs-Sound/dp/B08BHX1RMV?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Book/1_Pinkfong Baby Shark Sing-Alongs Sound Book.jpg`,
  },
  {
    number: 2,
    name: 'Pinkfong Baby Shark Animal Songs Sound Book',
    tag: 'Book',
    url: 'https://www.amazon.com/Pinkfong-Shark-Animal-Songs-Sound/dp/B09BQ6QLTF?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Book/2_Pinkfong Baby Shark Animal Songs Sound Book.jpg`,
  },
  {
    number: 3,
    name: 'Pinkfong Baby Shark Nursery Rhymes Sound Book',
    tag: 'Book',
    url: 'https://www.amazon.com/Pinkfong-Shark-Nursery-Rhymes-Sound/dp/B08LR4CS58?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Book/3_Pinkfong Baby Shark Nursery Rhymes Sound Book.jpg`,
  },
  {
    number: 4,
    name: 'Pinkfong Baby Shark Bedtime Songs Sound Book',
    tag: 'Book',
    url: 'https://www.amazon.com/Pinkfong-Shark-Bedtime-Songs-Sound/dp/B09BPX4T45?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Book/4_Pinkfong Baby Shark Bedtime Songs Sound Book.jpg`,
  },
  {
    number: 1,
    name: 'Baby Shark Song Puppet with Tempo Control',
    tag: 'Plush',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Shark-Plush-Puppet/dp/B07JR7NMYY?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Plush/1_Baby Shark Song Puppet with Tempo Control.jpg`,
  },
  {
    number: 2,
    name: 'Baby Shark Sing & Snuggle Plush',
    tag: 'Plush',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Baby-Shark-Official/dp/B084TQRSK7?ref_=ast_sto_dp&th=1&psc=1',
    price: 19.99,
    imagePath: `${baseDir}Plush/2_Baby Shark Sing & Snuggle Plush.jpg`,
  },
  {
    number: 3,
    name: 'Shark Family Song Cube',
    tag: 'Plush',
    url: 'https://www.amazon.com/WowWee-Pinkfong-Baby-Shark-Official/dp/B07K3JD7RF?ref_=ast_sto_dp&th=1&psc=1',
    price: 7.99,
    imagePath: `${baseDir}Plush/3_Shark Family Song Cube.jpg`,
  },
];
