import styled from '@emotion/styled';
import WaveAnimation from 'UI/atoms/WaveAnimation';
import Tabs, { TabsProps } from 'UI/molecules/Tabs';

const Container = styled.div`
  position: relative;
`;

interface TabsWithWaveProps extends TabsProps {
  width?: string;
}

function TabsWithWave(props: TabsWithWaveProps) {
  return (
    <Container>
      <Tabs {...props}>{props.children}</Tabs>
      <WaveAnimation />
    </Container>
  );
}

export default TabsWithWave;
