import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { SpaceEvenContainer } from 'styled/SpaceEvenContainer';
import { theme } from 'theme/theme';
import { HighlightedText, Stacker, CenteredSVG, GradationButton } from 'UI';

const headerFontSize = '60px';
const ButtonHighlightColor = '#ffc713';
const titleBorderSize = '7px';

const shakeAnimation = keyframes`
  0% { transform: translate(0px, 0px) }
  33% { transform: rotate(-22deg) }
  66% { transform: rotate(8deg)}
  100% { transform: rotate(0deg) }
`;

const ShakeAnimationBox = styled.div<{ shakeIt: boolean }>`
  animation: ${(props) =>
    props.shakeIt
      ? css`
          ${shakeAnimation} 0.5s 2 linear
        `
      : 'none'};
`;

const ContentDescFlexBox = styled(ColumnFlexBox)`
  width: 340px;
  position: relative;
`;

const ButtonContainer = styled.div`
  width: 60%;
`;

const ActivityButton = styled(GradationButton)`
  width: 100%;
  padding: 8px 16px;
  font-size: 24px;
  font-weight: 800;
  color: #f06e28;
`;

interface ActivityContentDescProps {
  onCraftButtonClick: () => void;
  onColoringButtonClick: () => void;
}

function ActivityContentDesc(props: ActivityContentDescProps) {
  const [firstImageShake, setFirstImageShake] = useState(false);
  const [secondImageShake, setSecondImageShake] = useState(false);

  return (
    <>
      <CenteredFlexBox>
        <HighlightedText
          size={headerFontSize}
          color={theme.colors.olly}
          borderColor="white"
          font="PinkfongBT"
          borderSize={titleBorderSize}>
          You can Have
          <HighlightedText
            size={headerFontSize}
            color={theme.colors.william}
            borderColor="white"
            font="PinkfongBT"
            borderSize={titleBorderSize}>
            {` Fun `}
          </HighlightedText>
          at
          <HighlightedText
            size={headerFontSize}
            color={theme.colors.william}
            borderColor="white"
            font="PinkfongBT"
            borderSize={titleBorderSize}>
            {` Home `}
          </HighlightedText>
        </HighlightedText>
      </CenteredFlexBox>
      <Stacker margin="xl" />

      <CenteredSVG src="/assets/BSandDoodle.svg" />

      <SpaceEvenContainer>
        <ContentDescFlexBox>
          <ShakeAnimationBox shakeIt={firstImageShake}>
            <ReactSVG src="/assets/Coloring.svg" />
          </ShakeAnimationBox>
          <Stacker margin="small" />
          <ButtonContainer
            onClick={() => {
              if (firstImageShake) return;
              setFirstImageShake(true);
              setTimeout(() => setFirstImageShake(false), 1000);
            }}>
            <ActivityButton
              highlightColor={ButtonHighlightColor}
              startColor="#ffaf1e"
              endColor="#ffaf1e"
              midColor="#ff9216"
              size="medium"
              onClick={props.onColoringButtonClick}>
              Coloring
            </ActivityButton>
          </ButtonContainer>
        </ContentDescFlexBox>
        <ContentDescFlexBox>
          <ShakeAnimationBox shakeIt={secondImageShake}>
            <ReactSVG src="/assets/Craft.svg" />
          </ShakeAnimationBox>
          <Stacker margin="small" />
          <ButtonContainer
            onClick={() => {
              if (secondImageShake) return;
              setSecondImageShake(true);
              setTimeout(() => setSecondImageShake(false), 1000);
            }}>
            <ActivityButton
              highlightColor={ButtonHighlightColor}
              startColor="#ffaf1e"
              endColor="#ffaf1e"
              midColor="#ff9216"
              size="medium"
              onClick={props.onCraftButtonClick}>
              Crafts
            </ActivityButton>
          </ButtonContainer>
        </ContentDescFlexBox>
      </SpaceEvenContainer>
      <Stacker margin="xl" />
    </>
  );
}

export default ActivityContentDesc;
