import styled from '@emotion/styled';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';
import { theme } from 'theme/theme';
import { SVGBackground, HighlightedText, Stacker, Spacer, Card, Text, ResponsiveYoutube } from 'UI';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { langState } from 'atoms/lang';
import { useEffect } from 'react';

const homePrimaryColor = '#0E294F';
const textBorderColor = '#005ae6';

const DescContainer = styled(CenteredFlexBox)`
  text-align: center;
  position: relative;
  flex-direction: column;
  min-width: 700px;
`;

function Desc() {
  const [lang] = useRecoilState(langState);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.code);
  }, [lang.code]);

  return (
    <>
      <SVGBackground src="/assets/HomeLeftLeafs.svg" left="3%" top="5px" />
      <SVGBackground src="/assets/HomeRightLeafs.svg" right="3%" top="50px" />
      <SVGBackground src="/assets/HomeLeftLeafs.svg" left="3%" top="850px" />
      <SVGBackground src="/assets/HomeRightLeafs.svg" right="3%" top="900px" />

      <DescContainer>
        <Spacer mb="125px" />
        {lang.code === 'ko' ? (
          <>
            <HighlightedText size="28px" color={homePrimaryColor} borderColor={theme.colors.white} font="NotoSans">
              유튜브 조회 수 1위{' '}
              <HighlightedText size="28px" color={theme.colors.olly} borderColor={theme.colors.white} font="NotoSans">
                아기상어
              </HighlightedText>
            </HighlightedText>
            <Spacer mb="5px" />
            <HighlightedText size="28px" color={homePrimaryColor} borderColor={theme.colors.white} font="NotoSans">
              글로벌 최고 키즈 채널{' '}
              <HighlightedText
                size="28px"
                color={theme.colors.william}
                borderColor={theme.colors.white}
                font="NotoSans">
                니켈로디언
              </HighlightedText>
              이 뭉쳤다!
            </HighlightedText>
          </>
        ) : (
          <>
            <HighlightedText size="28px" color={homePrimaryColor} borderColor={theme.colors.white} font="NotoSans">
              The No.1 Most-viewed YouTube Video,{' '}
              <HighlightedText size="28px" color={theme.colors.olly} borderColor={theme.colors.white} font="NotoSans">
                Baby Shark
              </HighlightedText>
            </HighlightedText>
            <Spacer mb="5px" />
            <HighlightedText size="28px" color={homePrimaryColor} borderColor={theme.colors.white} font="NotoSans">
              {`The World's Leading Kids' Channel, `}
              <HighlightedText
                size="28px"
                color={theme.colors.william}
                borderColor={theme.colors.white}
                font="NotoSans">
                Nickelodeon
              </HighlightedText>
              {`'s Global Fin-omenal Project`}
            </HighlightedText>
          </>
        )}
        <Stacker margin="xl" />

        <HighlightedText size="38px" color="white" borderColor={textBorderColor}>
          {t('home.desc.title_2')}
        </HighlightedText>
        <Spacer mb="8px" />

        <HighlightedText size="48px" color={theme.colors.olly} borderColor={textBorderColor}>
          {t('home.desc.title_3')}
        </HighlightedText>
        <Stacker margin="large" />

        <Card width="large" height="full">
          <ResponsiveYoutube src={t('home.desc.youtube_trailer_link')} />
        </Card>
        <Stacker margin="xl" />

        <Text size="22px" color={homePrimaryColor}>
          {t('home.desc.title_4')}
        </Text>

        <Stacker margin="medium" />

        <HighlightedText size="28px" color={homePrimaryColor} borderColor={theme.colors.white} borderSize="2px">
          {t('home.desc.title_5')}
        </HighlightedText>

        <Stacker margin="medium" />

        <Text size="22px" color={homePrimaryColor}>
          {t('home.desc.title_6')}
        </Text>
      </DescContainer>
      <Spacer mb="150px" />

      <Stacker margin="xl" />
    </>
  );
}

export default Desc;
