import styled from '@emotion/styled';

export const CenteredFlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CenteredFlexBox70vw = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70vw;
`;
