import styled from '@emotion/styled';
import { ReactNode } from 'react';

const defaultColor = '#005ae6';

interface ButtonStyles {
  size: 'small' | 'medium' | 'large';
  backgroundColor?: string;
  color?: string;
}

interface ButtonProps extends ButtonStyles {
  onClick?: () => void;
  children: ReactNode;
}

const buttonPaddings = {
  small: '4px 10px',
  medium: '6px 16px',
  large: '8px 22px',
};

const buttonBorderRadius = {
  small: '10px',
  medium: '12px',
  large: '18px',
};

const StyledButton = styled.button<ButtonStyles>`
  border-radius: ${(props) => buttonBorderRadius[props.size]};
  min-width: 64px;
  padding: ${(props) => buttonPaddings[props.size]};
  background-color: ${(props) => props.backgroundColor ?? defaultColor};
  color: ${(props) => props.color ?? props.theme.colors.white};
  border: 1.5px solid ${(props) => props.backgroundColor ?? defaultColor};
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;

  &:hover {
    border: 1.5px solid ${(props) => props.theme.colors.white};
  }
`;

function Button(props: ButtonProps) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}

export default Button;
