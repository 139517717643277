import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

interface TextStyles {
  color?: string;
  size?: string;
  lineHeight?: string;
  font?: string;
  marked?: boolean;
  align?: 'center' | 'left' | 'inherit';
  bold?: boolean;
}

export interface TextProps extends TextStyles {
  marks?: string[];
  markColor?: string;
  markStyle?: 'background' | 'bold';
  children: ReactNode;
}

const StyledText = styled.span<TextStyles>`
  color: ${(props) => props.color ?? props.theme.colors.primary};
  font-size: ${(props) => props.size ?? 'inherit'};
  white-space: pre-line;
  position: relative;
  line-height: ${(props) => props.lineHeight ?? 'inherit'};
  z-index: 1;
  font-weight: ${(props) => (props.marked || props.bold ? '800' : '700')};
  font-family: ${(props) => props.font ?? 'NotoSans'};
  text-align: ${(props) => props.align ?? 'inherit'};
`;

const Marked = styled.div<{ delta: number; markColor?: string }>`
  position: absolute;
  background-color: ${(props) => props.markColor ?? '#5fe1ff'};
  width: 100%;
  height: calc(100% - ${(props) => props.delta + 'px'});
  top: ${(props) => props.delta + 'px'};
  left: 0;
  z-index: -1;
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

function splitMarkText(marks: string[], text: string) {
  const splitedTexts: string[] = [];
  let leftText = text;

  marks.forEach((mark) => {
    const split = leftText.split(mark);
    if (split[0] === leftText) return;
    splitedTexts.push(split[0]);
    splitedTexts.push(mark);
    leftText = split[1];
  });
  splitedTexts.push(leftText);

  return splitedTexts;
}

function Text(props: TextProps) {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });
  const delta = Number(props.size?.replace('px', '') ?? 0) * (isMobile ? 1.2 : 1);
  const markStyle = props.markStyle ?? 'background';

  if (props.marks !== undefined && props.marks.length > 0) {
    const marks = props.marks;
    const splitedTexts: string[] = splitMarkText(marks, props.children?.toString() ?? ' ');

    return (
      <CenteredDiv>
        {splitedTexts.map((text, index) => {
          return (
            <StyledText marked={marks.includes(text)} key={`marked-span-${index}`} {...props}>
              {marks.includes(text) && markStyle === 'background' && (
                <Marked delta={delta} markColor={props.markColor} />
              )}
              {text}
            </StyledText>
          );
        })}
      </CenteredDiv>
    );
  }

  return (
    <StyledText {...props}>
      {props.marked && <Marked delta={delta} markColor={props.markColor} />}
      {props.children}
    </StyledText>
  );
}

export default Text;
