import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import useMousePosition from 'hooks/useMousePosition';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

interface AnimationPoint {
  id: number;
  x: number;
  y: number;
}

const slideToUpper = keyframes`
  0% {
    transform: translateY(-200%);
    opacity: 1;
  }
  99% {
    transform: translateY(-400%);
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;

const CursorAnimation = styled.div<{ imageUrl: string; x: string; y: string }>`
  position: fixed;
  top: ${(props) => props.y};
  left: ${(props) => props.x};
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.imageUrl});
  animation: ${slideToUpper} 1s 1 linear forwards;
  z-index: 999;
  pointer-events: none;
`;

const Cursor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  background: url('/assets/Cursor.svg') no-repeat;
  z-index: 998;
  pointer-events: none;
`;

const animationImages = ['CursorBubble1.svg', 'CursorBubble2.svg'];
let index = 1;

function CustomCursor() {
  const { x, y } = useMousePosition();
  const [animationStartPositions, setAnimationStartPositions] = useState<AnimationPoint[]>([]);
  const [maxIndex, setMaxIndex] = useState(0);

  function RunAnimation(x: number, y: number) {
    const positions = animationStartPositions;
    if (positions.length > 10) {
      positions.shift();
    }
    setMaxIndex((maxIndex) => {
      return maxIndex + 1;
    });
    positions.push({ id: index++, x: x, y: y });
    setAnimationStartPositions([...positions]);
  }

  const animationHandler = useCallback(
    _.throttle((x: number, y: number) => RunAnimation(x + _.random(-15, 5), y + 20), 200),
    [],
  );

  useEffect(() => {
    animationHandler(x, y);
  }, [x, y]);

  return (
    <>
      {animationStartPositions.map((imageUrl, index) => {
        return (
          <CursorAnimation
            id={`cursorAnimation-${maxIndex - animationStartPositions.length + index}`}
            key={`cursorAnimation-${maxIndex - animationStartPositions.length + index}`}
            imageUrl={`/assets/${animationImages[animationStartPositions[index].id % 2]}`}
            x={`${animationStartPositions[index].x ?? -5}px`}
            y={`${animationStartPositions[index].y ?? -5}px`}
          />
        );
      })}
      <Cursor style={{ left: `${x}px`, top: `${y}px` }} />
    </>
  );
}

export default CustomCursor;
