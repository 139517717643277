import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { calculateStrokeTextCSS } from 'lib/math';
import { ReactNode } from 'react';
import { useRecoilState } from 'recoil';

interface HighlightedTextStyles {
  size?: string;
  borderSize?: string;
  color?: string;
  font?: string;
  borderColor?: string;
  lineHeight?: string;
}

interface HighlightedTextProps extends HighlightedTextStyles {
  children: ReactNode;
}

export const StrokeText = styled.span<HighlightedTextStyles>`
  text-shadow: ${(props) => calculateStrokeTextCSS(32, props.borderColor ?? 'transparent', props.borderSize)};
  color: ${(props) => props.color ?? props.theme.colors.primary};
  font-size: ${(props) => props.size ?? 'inherit'};
  font-family: ${(props) => props.font ?? 'ManwhaGage'};
  text-align: center;
  position: relative;
  white-space: pre-line;
  line-height: ${(props) => props.lineHeight};
`;

function HighlightedText(props: HighlightedTextProps) {
  const [lang] = useRecoilState(langState);
  const defaultFont = lang.code === 'ko' ? 'ManwhaGage' : 'PinkfongBT';

  return (
    <StrokeText {...props} font={props.font ?? defaultFont}>
      {props.children}
    </StrokeText>
  );
}

export default HighlightedText;
