interface Activity {
  src: string;
  tag: string;
}

export const Activities: Activity[] = [
  {
    src: 'BF_Birthday Pack_A4_221018_zip',
    tag: 'crafts',
  },
  {
    src: 'BirthdayEvent',
    tag: 'coloring',
  },
  {
    src: 'Nick_EasterEgg_Coloring',
    tag: 'coloring',
  },
  {
    src: 'BirthdayPack',
    tag: 'coloring',
  },
  {
    src: '1_Baby-Sharks-Big-Show-Coloring-Pack',
    tag: 'coloring',
  },
  {
    src: '2_PFBS1',
    tag: 'coloring',
  },
  {
    src: '3_PFBS2',
    tag: 'coloring',
  },
  {
    src: '4_PFBS3',
    tag: 'coloring',
  },
  {
    src: '5_PFBS4',
    tag: 'coloring',
  },
  {
    src: '6_PFBS5',
    tag: 'coloring',
  },
  {
    src: '7_PFBS6',
    tag: 'coloring',
  },
  {
    src: '8_PFBS_Difference',
    tag: 'coloring',
  },
  {
    src: '9_PFHG_Difference',
    tag: 'coloring',
  },
  {
    src: '10_PFWS1',
    tag: 'coloring',
  },
  {
    src: '11_PFWS2',
    tag: 'coloring',
  },
  {
    src: '12_PF1',
    tag: 'coloring',
  },
  {
    src: '13_PF2',
    tag: 'coloring',
  },
  {
    src: '14_PF3',
    tag: 'coloring',
  },
  {
    src: '15_PF4',
    tag: 'coloring',
  },
  {
    src: '16_PF5',
    tag: 'coloring',
  },
  {
    src: '17_PF6',
    tag: 'coloring',
  },
  {
    src: 'Nick_EasterEgg_holder',
    tag: 'crafts',
  },
  {
    src: 'BirthdayPack',
    tag: 'crafts',
  },
  {
    src: '1_BS_FAMILY_TREE_PRINTABLE_2x3',
    tag: 'crafts',
  },
  {
    src: '2_Baby_Shark_Headbands',
    tag: 'crafts',
  },
  {
    src: '3_Baby-Shark-Feed-The-Shark-Family-Game',
    tag: 'crafts',
  },
  {
    src: '4_PF_PD1',
    tag: 'crafts',
  },
  {
    src: '5_PF_PD2',
    tag: 'crafts',
  },
  {
    src: '6_PF_PD3',
    tag: 'crafts',
  },
  {
    src: '7_PF_PD4',
    tag: 'crafts',
  },
  {
    src: '8_PFWS1_M',
    tag: 'crafts',
  },
  {
    src: '9_PFWS2_M',
    tag: 'crafts',
  },
  {
    src: '10_PFWS3_M',
    tag: 'crafts',
  },
  {
    src: '11_PFWS4_M',
    tag: 'crafts',
  },
  {
    src: '12_PFWS5_M',
    tag: 'crafts',
  },
];
