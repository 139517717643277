import { Theme } from '@emotion/react';

export const theme: Theme = {
  colors: {
    primary: '#039BE5',
    secondary: '#F4511E',
    warning: '#FB8C00',
    positive: '#35D472',
    negative: '#e53935',
    error: '#e53935',
    white: '#FAFAFA',
    realWhite: '#FFFFFF',
    black: '#212121',
    gray: '#eeeeee',
    darkGray: '#a3a3a3',
    home: '#5fe1ff',
    homeContent: '#3b9fff',
    activity: '#46c81e',
    activityContent: '#46c81e',
    shop: '#916ef5',
    shopContent: '#ffc0f4',
    pinkfongPlus: '#ff73c6',
    tabDefault: '#64c8ff',
    tabActive: '#3ca0ff',
    olly: '#ffd700',
    william: '#ff7732',
    mom: '#e66ec8',
    daddy: '#0a6ec3',
    grandma: '#f58219',
    grandpa: '#78be46',
  },
};
