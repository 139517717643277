import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { useRecoilState } from 'recoil';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { sizes } from 'theme/size';
import { Stacker } from 'UI/atoms/Spacer';
import { Card, Text } from '../';

const shopCardColor = '#fdb4ea';
const shopTextColor = '#f578c3';

interface ShopCardProps {
  src: string;
  name: string;
  price: number;
  url: string;
}

const ShopCardContainer = styled(ColumnFlexBox)`
  display: inline-flex;
  width: 33%;
  @media ${sizes.device.mobile} {
    width: 50%;
  }

  & > span {
    width: 60%;
  }
  margin-bottom: 60px;
  justify-content: center;
  align-items: center;
`;

function ShopCard({ src, name, price, url }: ShopCardProps) {
  const [lang] = useRecoilState(langState);
  const currency = lang.code === 'ko' ? 'KRW' : 'USD';

  const currencyFormatter = new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: currency,
  });

  return (
    <ShopCardContainer>
      <Card backgroundColor={shopCardColor} borderless height="medium" width="medium" onClick={() => window.open(url)}>
        <img src={encodeURIComponent(src)} alt="Shop image for sale" width="100%" />
      </Card>
      <Stacker margin="xs" />

      <Text color="black" size="28px" align="center">
        {name}
      </Text>

      <Text color={shopTextColor} size="24px" align="center">
        {currencyFormatter.format(price)}
      </Text>
    </ShopCardContainer>
  );
}

export default ShopCard;
