import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';

interface SVGButtonProps {
  src: string;
  background?: string;
  onClick?: () => void;
}

const StyledSVGButton = styled.button<{ background: string }>`
  border-radius: 50%;
  background-color: transparent;
  border: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.background};
  }
`;

function SVGButton({ src, onClick, background = 'transparent' }: SVGButtonProps) {
  return (
    <StyledSVGButton onClick={onClick} background={background}>
      <ReactSVG src={src} />
    </StyledSVGButton>
  );
}

export default SVGButton;
