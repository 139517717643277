import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import HighlightedText from 'UI/atoms/HighlightedText';
import { Stacker } from 'UI/atoms/Spacer';

const borderColor = '#ffe1fa';
const textColor = '#fa82e6';

interface ShopOrderButtonProps {
  imageUrl: string;
  text: string;
  selected: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
}

const CardContainer = styled(ColumnFlexBox)<{ selected: boolean; width: string; height: string }>`
  padding: 15px;
  position: relative;
  background: ${(props) => (props.selected ? `no-repeat center/105% url('assets/shop/ShopClickBubble.svg')` : 'none')};
  justify-content: flex-end;
  & > div > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: ${(props) => props.width};
      height: auto;
    }
  }

  :hover {
    background: no-repeat center/105% url('assets/shop/ShopClickBubble.svg');
  }
`;

function ShopOrderButton(props: ShopOrderButtonProps) {
  const width = props.width ?? '60%';

  return (
    <CardContainer selected={props.selected} onClick={props.onClick} width={width} height="100%">
      <ReactSVG src={props.imageUrl} />
      <Stacker margin="xs" />

      <HighlightedText color={textColor} borderColor={borderColor} size="42px" font="PinkfongBT">
        {props.text}
      </HighlightedText>
    </CardContainer>
  );
}

export default ShopOrderButton;
