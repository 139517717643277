import styled from '@emotion/styled';
import { Activities as activities } from 'constants/Activities';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MobileContainer } from 'styled/MobileContainer';
import { theme } from 'theme/theme';
import { ActivityContentDesc, BBOWFooter, BBOWHeader, Spacer, Stacker, SVGBackground } from 'UI';
import ActivityContentCard from 'UI/organisms/ActivityContentCard';

const ContentSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.activityContent};
  padding-top: 50px;
  position: relative;
  min-height: 2500px;
  overflow: hidden;
`;

const CardContainer = styled.article`
  width: 90%;
`;

function Activity() {
  const query = new URLSearchParams(useLocation().search);
  const [currentTag, setCurrentTag] = useState<string | null>(null);
  const targetActivity = query.get('id');

  const background = [...Array(6)].map((value, index) => {
    return (
      <>
        <SVGBackground src="/assets/backgrounds/ActivityBG-1.svg" top={`${50 + index * 1200}px`} left="0px" />
        <SVGBackground src="/assets/backgrounds/ActivityBG-2.svg" top={`${25 + index * 1200}px`} right="0px" />
      </>
    );
  });

  useEffect(() => {
    if (targetActivity != null) {
      setTimeout(() => {
        document.getElementById(targetActivity)?.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  });

  return (
    <MobileContainer>
      <BBOWHeader />
      <Spacer mt="-2px" />
      <ContentSection>
        {background}
        <ActivityContentDesc
          onCraftButtonClick={() => setCurrentTag('crafts')}
          onColoringButtonClick={() => setCurrentTag('coloring')}
        />
        <Stacker margin="xl" />

        <CardContainer>
          {activities.map((act, index) => {
            if (currentTag === null || act.tag === currentTag)
              return <ActivityContentCard key={`act-${index}`} src={act.src} tag={act.tag} />;
            else return <></>;
          })}
        </CardContainer>
      </ContentSection>
      <BBOWFooter backgroundColor={theme.colors.activityContent} />
    </MobileContainer>
  );
}

export default Activity;
