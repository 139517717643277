import styled from '@emotion/styled';
import { ColumnFlexBox } from 'styled/ColumnFlexBox';
import { Card, Stacker, SVGButton } from 'UI';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

const CardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  :first-of-type {
    margin-right: 8px;
  }
`;

const ActivityContainer = styled(ColumnFlexBox)`
  display: inline-flex;
  width: 50%;
  margin-bottom: 60px;
`;

const ActivityCard = styled(Card)`
  width: 80%;
  height: auto;
`;

interface ActivityContentCardProps {
  src: string;
  tag: string;
}

function ActivityContentCard({ src, tag }: ActivityContentCardProps) {
  function shareHandler() {
    navigator.clipboard.writeText(`${window.location.host}/activity?id=${src}`);

    toast.success('클립보드에 복사되었습니다!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function downloadHandler(suffix: 'zip' | 'pdf') {
    saveAs(`/assets/activity/${tag}/${src}.${suffix}`, src);
  }

  return (
    <ActivityContainer id={src}>
      <ActivityCard backgroundColor="#41b90f" borderless height="medium" width="medium">
        <img src={`/assets/activity/${tag}/thumbnails/${src}.jpg`} alt="Activity Thumbnail" width="100%" />
      </ActivityCard>
      <Stacker margin="small" />
      <CardButtonContainer>
        <SVGButton src="/assets/icons/share.svg" background="#44c218" onClick={() => shareHandler()} />
        <SVGButton
          src="/assets/icons/download.svg"
          background="#44c218"
          onClick={() => downloadHandler(src.includes('zip') ? 'zip' : 'pdf')}
        />
      </CardButtonContainer>
    </ActivityContainer>
  );
}

export default ActivityContentCard;
