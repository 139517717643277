import styled from '@emotion/styled';

interface ImageBackgroundProps {
  src: string;
  alt: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
}

const BackgroundContainer = styled.div<{
  width?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}>`
  position: absolute;
  top: ${(props) => props.top ?? ''};
  bottom: ${(props) => props.bottom ?? ''};
  left: ${(props) => props.left ?? ''};
  right: ${(props) => props.right ?? ''};
  width: ${(props) => props.width ?? '100%'};
  max-height: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

function ImageBackground({ width, src, top, bottom, left, right, alt }: ImageBackgroundProps) {
  return (
    <BackgroundContainer width={width} top={top} bottom={bottom} left={left} right={right}>
      <img src={src} alt={alt} />
    </BackgroundContainer>
  );
}

export default ImageBackground;
