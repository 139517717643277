import { styled } from '@storybook/theming';
import { space } from 'styled-system';

const marginPixels = {
  xs: '15px',
  small: '25px',
  medium: '30px',
  large: '35px',
  xl: '60px',
  xxl: '100px',
};

export const Spacer = styled.div(space);
export function Stacker({ margin }: { margin: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl' }) {
  return <Spacer mb={marginPixels[margin]} />;
}
