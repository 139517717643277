import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { CardWithPropImg, SVGBackground } from 'UI';

const CardContainer = styled.div`
  position: relative;
`;

function CanDooItCard() {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  });

  return (
    <CardContainer>
      <SVGBackground src="/assets/event/ICanDooIt/WilliamAlone.png" top="-35px" right="-65px" width="150px" />
      <CardWithPropImg width={isMobile ? '70vw' : '30vw'}>
        <video src="https://babyshark.tv/assets/videos/big_show_event.mp4" width="100%" autoPlay controls />
      </CardWithPropImg>
      <SVGBackground src="/assets/GMandGPSharks.svg" bottom="-90px" left="-75px" width="220px" />
    </CardContainer>
  );
}

export default CanDooItCard;
