import FadeIn from 'react-fade-in';
import { useInView } from 'react-intersection-observer';
import { CenteredSVG } from 'UI';

interface FadeInSVGImgProps {
  src: string;
}

function FadeInSVGImg({ src }: FadeInSVGImgProps) {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });

  return (
    <div ref={ref}>
      <FadeIn visible={inView}>
        <CenteredSVG src={src} />
      </FadeIn>
    </div>
  );
}

export default FadeInSVGImg;
