import styled from '@emotion/styled';

export const ResponsiveDiv = styled.div<{ mobileWidth?: string; desktopWidth?: string }>`
  width: ${(props) => props.desktopWidth ?? '100%'};
  @media only screen and (max-width: 750px) {
    width: ${(props) => props.mobileWidth ?? '100%'};
  }
`;

export const ResponsiveHeader = styled.header<{ mobileWidth?: string; desktopWidth?: string }>`
  width: ${(props) => props.desktopWidth ?? '100%'};
  @media only screen and (max-width: 750px) {
    width: ${(props) => props.mobileWidth ?? '100%'};
  }
`;
