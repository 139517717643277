import styled from '@emotion/styled';
import { ReactNode } from 'react';

interface CardStyles {
  backgroundColor?: string;
  borderColor?: string;
  borderless?: boolean;
  shadow?: boolean;
  width: 'small' | 'medium' | 'large' | 'full' | 'auto' | 'fitScreen' | 'mobile' | 'prize';
  height: 'small' | 'medium' | 'large' | 'full' | 'auto' | 'prize';
  padding?: string;
}

interface CardProps extends CardStyles {
  onClick?: () => void;
  children: ReactNode;
}

const cardSizes = {
  small: '175px',
  medium: '275px',
  prize: '365px',
  large: '485px',
  full: '100%',
  auto: 'auto',
  fitScreen: '75vw',
  mobile: '600px',
};

const StyledCard = styled.div<CardStyles>`
  position: relative;
  border-radius: 15px;
  border: ${(props) => (props.borderless ? 0 : '1rem solid ' + (props.borderColor ?? props.theme.colors.home))};
  background-color: ${(props) => props.backgroundColor ?? props.theme.colors.primary};
  width: ${(props) => cardSizes[props.width]};
  height: ${(props) => cardSizes[props.height]};
  overflow: hidden;
  box-shadow: ${(props) => (props.shadow ? '3px 3px 3px lightgray' : 'inherit')};
  padding: ${(props) => props.padding ?? '0'};
`;

function Card(props: CardProps) {
  return <StyledCard {...props}>{props.children}</StyledCard>;
}

export default Card;
