import styled from '@emotion/styled';
import { userState } from 'atoms/user';
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { Text } from 'UI';
import { sizes } from 'theme/size';

const PID_LOGIN_API_END_POINT: string =
  process.env.NODE_ENV === 'development'
    ? 'https://f65oclex2j.execute-api.ap-northeast-1.amazonaws.com/default/connect'
    : 'https://z71f249j37.execute-api.ap-northeast-1.amazonaws.com/default/connect';

const StyledButton = styled.button`
  & > span {
    margin-left: 5%;
    font-size: 20px;
    color: #c7c7c7;
    @media ${sizes.device.mobile} {
      display: none;
    }
  }

  border-radius: 50%;
  background-color: transparent;
  border: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin-bottom: 5px;
`;

function LoginButton() {
  const [user] = useRecoilState(userState);

  useEffect(() => {
    console.log(user.userName);
  }, [user]);

  function tryLogin() {
    window.location.href = PID_LOGIN_API_END_POINT;
  }

  return (
    <StyledButton>
      <ReactSVG src="/assets/icons/LoginIcon.svg" onClick={tryLogin} />
      {user.userName === '' ? <Text font="PinkfongBT">Login</Text> : <></>}
    </StyledButton>
  );
}

export default LoginButton;
