import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CardWithPropImg, ResponsiveYoutube, SVGBackground } from 'UI';

const CardContainer = styled.div`
  position: relative;
`;

function HomeWatchCard() {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <SVGBackground src="/assets/MomSharkAlone.svg" top="-105px" right="-65px" width="150px" />
      <SVGBackground src="/assets/DadSharkAlone.svg" bottom="-70px" left="-75px" width="150px" />
      <CardWithPropImg>
        <ResponsiveYoutube src={t('home.watch.shorts_link')} />
      </CardWithPropImg>
    </CardContainer>
  );
}

export default HomeWatchCard;
