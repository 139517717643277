import styled from '@emotion/styled';
import Text, { TextProps } from 'UI/atoms/Text';

export interface TextWithBackgroundProps extends TextProps {
  backgroundColor?: string;
  height?: string;
  borderRadius?: string;
  padding?: string;
}

const TextContainer = styled.div<{
  height?: string;
  backgroundColor: string;
  size: string;
  borderRadius?: string;
  padding?: string;
}>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius ?? 'none'};
  padding: ${(props) => props.padding ?? '6px 8px'};
  position: relative;
  height: ${(props) => props.height ?? 'auto'};
  line-height: ${(props) => props.height ?? 'inherit'};
  width: fit-content;
`;

function TextWithBackground({
  height,
  backgroundColor = '#0082ff',
  children,
  size,
  color,
  borderRadius,
  padding,
  font,
}: TextWithBackgroundProps) {
  return (
    <TextContainer
      height={height}
      size={size ?? '10px'}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      padding={padding}>
      <Text font={font ?? 'ManwhaGage'} size={size} color={color}>
        {children}
      </Text>
    </TextContainer>
  );
}

export default TextWithBackground;
