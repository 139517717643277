const deviceSizes = {
  mobile: '1024px',
};

const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile})`,
};

export const sizes = {
  device,
  deviceSizes,
};
