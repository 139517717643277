import styled from '@emotion/styled';
import { langState } from 'atoms/lang';
import { toys, toysEn } from 'constants/Toys';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useRecoilState } from 'recoil';
import { CenteredFlexBox } from 'styled/CenteredFlexBox';
import { MobileContainer } from 'styled/MobileContainer';
import { sizes } from 'theme/size';
import { theme } from 'theme/theme';
import { BBOWFooter, BBOWHeader, HighlightedText, ShopCard, Spacer, Stacker, SVGBackground } from 'UI';
import ShopOrderButton from 'UI/organisms/ShopOrderButton';

const headerFontSize = '60px';
const headerHighlightColor = '#ffe678';
const headerColor = '#f0e1ff';
const headerBorderColor = '#6e47e3';

const highlightBorderSize = '7px';

const ContentSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.shopContent};
  position: relative;
  overflow: hidden;
`;

const TitleContainer = styled(CenteredFlexBox)`
  line-height: 80px;
`;

const FirstContentContainer = styled(CenteredFlexBox)`
  width: 80%;
  @media ${sizes.device.mobile} {
    flex-direction: column;
  }
`;

const ButtonContainer = styled(CenteredFlexBox)`
  width: 80%;
  max-width: 600px;
  margin-top: 8%;
  margin-bottom: 6%;
  position: relative;

  & > div > div {
    display: inline-flex;
    width: 250px;
    height: 250px;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  & > div:nth-of-type(odd) {
    margin-right: 15px;
  }
`;

const ShopProductContainer = styled.article`
  width: 100%;
`;

const tags = ['Best', 'Toy', 'Book', 'Plush'];

function Shop() {
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [lang] = useRecoilState(langState);
  const currentToys = lang.code === 'ko' ? toys : toysEn;
  const { t } = useTranslation();

  function onChangeOrder(index: number) {
    setSelectedButton(selectedButton === index ? null : index);
  }

  return (
    <MobileContainer>
      <BBOWHeader />
      <Spacer mt="-2px" />
      <ContentSection>
        <SVGBackground src="/assets/backgrounds/ShopBG.svg" top="-2px" width="100%" />
        <Stacker margin="xxl" />

        <FirstContentContainer>
          <ReactSVG src="/assets/BSandW.svg" />

          <ButtonContainer>
            <div>
              <ShopOrderButton
                selected={selectedButton === 0}
                imageUrl="/assets/ShopBest.svg"
                text="Best"
                onClick={() => onChangeOrder(0)}
              />
              <ShopOrderButton
                selected={selectedButton === 1}
                imageUrl="/assets/ShopToy.svg"
                text="Toy"
                width="80%"
                onClick={() => onChangeOrder(1)}
              />
            </div>
            <div>
              <ShopOrderButton
                selected={selectedButton === 2}
                imageUrl="/assets/ShopBook.svg"
                text="Book"
                width="90%"
                onClick={() => onChangeOrder(2)}
              />
              <ShopOrderButton
                selected={selectedButton === 3}
                imageUrl="/assets/ShopPlush.svg"
                text="Plush"
                width="70%"
                onClick={() => onChangeOrder(3)}
              />
            </div>
          </ButtonContainer>
        </FirstContentContainer>

        <TitleContainer>
          <HighlightedText
            size={headerFontSize}
            color={headerColor}
            borderColor={headerBorderColor}
            borderSize={highlightBorderSize}>
            {t('shop.title_1')}
            <HighlightedText
              size={headerFontSize}
              color={headerHighlightColor}
              borderColor={headerBorderColor}
              borderSize={highlightBorderSize}>
              {t('shop.title_2')}
            </HighlightedText>
            {t('shop.title_3')}
            {t('shop.title_4')}
          </HighlightedText>
          <Stacker margin="small" />
        </TitleContainer>

        <Stacker margin="xl" />

        <ShopProductContainer>
          {currentToys.map((toy, index) => {
            if (selectedButton === null || toy.tag === tags[selectedButton]) {
              return (
                <ShopCard key={`toy-${index}`} name={toy.name} src={toy.imagePath} price={toy.price} url={toy.url} />
              );
            }
            return null;
          })}
        </ShopProductContainer>
      </ContentSection>
      <BBOWFooter backgroundColor={theme.colors.shopContent} />
    </MobileContainer>
  );
}

export default Shop;
