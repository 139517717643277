import styled from '@emotion/styled';
import { StrokeText } from 'UI/atoms/HighlightedText';
import { calculateStrokeTextCSS } from 'lib/math';
import { useRecoilState } from 'recoil';
import { langState } from 'atoms/lang';

interface StrokeTextStyles {
  size?: string;
  color?: string;
  borderColor?: string;
  radius: number;
  degree: number;
  arc: number;
  index: number;
  font?: string;
}

const CurvedText = styled(StrokeText)<StrokeTextStyles>`
  height: ${(props) => props.radius + 'px'};
  transform: rotate(${(props) => props.degree * props.index - props.arc / 2}deg);
  transform-origin: ${(props) => '0 ' + props.radius + 'px 0'};
  position: absolute;
  text-shadow: ${(props) => calculateStrokeTextCSS(32, props.borderColor ?? 'transparent')};
  color: ${(props) => props.color ?? props.theme.colors.primary};
  font-size: ${(props) => props.size ?? 'inherit'};
  font-family: ${(props) => props.font ?? 'ManwhaGage'};
  text-align: center;
`;

const CurvedH1 = styled.h1`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 300px;
`;

const Container = styled.div`
  position: relative;
`;

interface CurvedHeaderProps {
  size?: string;
  color?: string;
  borderColor?: string;
  text: string;
  arc?: number;
  radius?: number;
}

function CurvedHeader({ text, arc = 30, radius = 850, size, color, borderColor }: CurvedHeaderProps) {
  const [lang] = useRecoilState(langState);
  const font = lang.code === 'ko' ? 'ManwhaGage' : 'PinkfongBT';

  const characters = text.split('');
  const spaces = text.match(/ /g)?.length ?? 0;
  const degree = arc / (characters.length - spaces);
  let delta = 0;

  return (
    <Container>
      <CurvedH1>
        {characters.map((char, i) => {
          if (char !== ' ') {
            return (
              <CurvedText
                size={size}
                color={color}
                borderColor={borderColor}
                key={`curved-text-${i}`}
                radius={radius}
                degree={degree}
                arc={arc}
                index={i - delta}
                font={font}>
                {char === ' ' ? '' : char}
              </CurvedText>
            );
          }
          delta += 0.6;
          return <div key={`curved-text-${i}`} />;
        })}
      </CurvedH1>
    </Container>
  );
}

export default CurvedHeader;
