import { atom } from 'recoil';

export type Language = {
  code: string;
};

export const langState = atom<Language>({
  key: 'langState',
  default: {
    code: 'ko',
  },
});
